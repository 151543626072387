import React from 'react';
import './Footer.css';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquareInstagram, faFacebook, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faPhone, faEnvelope, faClock, faLocationDot } from '@fortawesome/free-solid-svg-icons';
import FB from "../image/footerFB.png";
import IN from "../image/footerIN.png";
import INSTA from "../image/footerINSTA.png";
import loc from '../image/Group.png';



const Footer = () => {
  return (
    <div className="footer" style={{ position: 'relative', }}>
      <div className="footer-column-contact">
        <h3 className="footer-title">REACH US</h3>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
          <div className="contact-address">
            <img src={loc} size="1x" id="social-icon" />
            <span>Registered Office</span>
          </div>
          <div className="address-lines">
            <span style={{marginLeft:'20px'}}>26, Deepak Society, Chuna Bhatti</span>
            <span>Kolar Road, Bhopal-462042, M.P.</span>
          </div>
        </div>
     
      <div className="social-iconblock">
      <a href="https://www.facebook.com/CarzCafe.bpl?mibextid=rS40aB7S9Ucbxw6v">
          <img src={FB} size="2x" id="social-icon" style={{ margin: '0 5px' }} />
        </a>
         <a href="https://www.instagram.com/carzcafe.bpl?igsh=MnB6ZnpiMzNxdDVk">
          <img src={INSTA} size="2x" id="social-icon" style={{ margin: '0 5px' }} />
        </a>
      
        <a href="https://www.linkedin.com/company/carzcafe/">
          <img src={IN} size="2x" id="social-icon" style={{ margin: '0 5px' }} />
        </a>
      </div>
      </div>
      <div className="footer-column-policy">
        <h3 className="footer-title" style={{marginLeft:'25px'}}>POLICY</h3>
        <div className="footer-policy">
          <ul>
            <li><Link to="/termsnondition">Terms & Conditions</Link></li>
            <li><Link to="/privacypolicy">Privacy Policies</Link></li>
            <li><Link to="/cookiespolicy">Cookies Policies</Link></li>
          </ul>
        </div>
      </div>
      <div className="footer-column-followus">
        <h3 className="footer-title">SUPPORT</h3>
        <div className="footer-policy">
          <div style={{ display: 'flex', alignItems: 'center', gap: '10px', marginBottom: '5px' }}>
            <FontAwesomeIcon icon={faEnvelope} />
            <a className="gmail" href="mailto:autokaar@gmail.com">autokaar@gmail.com</a>
          </div>
          <div style={{ display: 'flex', alignItems: 'center', gap: '10px', marginBottom: '5px' }}>
            <FontAwesomeIcon icon={faPhone} />
            <a className="phone" href="tel:+919981040082">+919981040082</a>
          </div>
          <div style={{ display: 'flex', alignItems: 'center', gap: '10px',marginBottom: '5px' }}>
            <FontAwesomeIcon icon={faClock} />
            <p className="Time" style={{margin:'0'}}>Timing 8:00AM to 8:00PM</p>
          </div>        
        </div>
      </div>
    </div>
  );
}

export default Footer;
