import React, { useState, useEffect, useRef } from "react";
import Services from "./Ourservice";
import "./Homepage.css";
import CarInfo from './Carage';
import { useNavigate } from "react-router-dom";

function Homepage() {
  const [activeSection, setActiveSection] = useState(0);
  const [hasSlid, setHasSlid] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [location, setLocation] = useState("Bhopal");
  const [query, setQuery] = useState("");
  const [filteredItems, setFilteredItems] = useState([]);
  const [activeSuggestionIndex, setActiveSuggestionIndex] = useState(0);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [highlightedIndex, setHighlightedIndex] = useState(-1);
  const dropdownRef = useRef(null);
  const navigate = useNavigate();

  const totalSections = 2;

  const items = [
    "PPF",
    "Paint Protection Films (PPF)",
   " Wraps and Films",
"Car Paint Lamination",
"Car Wrap Protection",
"Self-Healing Film",
"Car Paint Protection Films ",
"Scratch Resistance",
"Stone Chip Protection",
"Hydrophobic Layer",
"Top Coat Protection",
"Full Body PPF",
"Custom PPF ",
"Professional PPF",
"Corrosion Protection",
"Scratch Resistance Film/Layer",
"Car Surface Protection film",
"Car Exterior Protection film",

"Ceramic Coating", 
" Nano-ceramic coating",
"Hydrophobic coating",
"Graphene Coating",
"Gloss enhancement",
"UV protection",
"Long-lasting shine",
"Professional ceramic coating",
"Surface durability",
"Swirl mark prevention",
"Deep paint gloss",
"Fade-resistant finish",

"Interior car care",
"Car interior cleaning ",
"Car interior detailing",
"Car interior restoration",
"Car interior maintenance",
"Deep cleaning car interior",
"Interior car care products",
"Stain removal ",
"Car interior disinfectant",
"Economic interior cleaning",
"Budget interior cleaning",
"Affordable car interior cleaning",
"Inexpensive interior cleaning ",
"Low-cost interior cleaning",
"Cost-effective interior cleaning", 
"Basic car interior cleaning",
"Minimalist car interior cleaning", 
"Simple interior cleaning ",
"Basic maintenance interior cleaning", 
"Foam based interior cleaning",
"Foam cleaner",
"Leather cleaner",
"Antimicrobial car cleaner",
"Germ-killing", 
"Antibacterial",
"Bacteria removal", 
"Disinfectant",
"Odor and germ eliminator",
"germ protection",
"Germ-resistant",
"Antimicrobial",
"Sanitizing",
"Deep cleaning",
"Deep interior",
"Thorough Cleaning",
"Full car Cleaning", 
"Complete interior", 
"Intensive Cleaning", 
"Professional cleaning",

"exterior care",
"Car UV paint protection",
"UV paint protection",
"UV shield",
"UV-resistant",
"Sun damage prevention",
"sun protection",
"Paint fading prevention",
"Long-lasting UV protection",
"UV blocker", 
"Teflon Coating",
"Ticket polish",
"Paint correction",
"Surface polishing",
"Scratch removal",
"Swirl mark elimination",
"Paint finishing",
"Paint enhancement",
"Deep scratch repair",
"Professional paint correction",
"Polishing",
"Car surface refinement",
"Surface refinement",
"Paint enhancement",
"Gloss restoration",
"Surface smoothing",
"Surface decontamination",
"Surface preparation",
"Finish enhancement",
"Deep shine",
"Refined gloss finish",
"Surface correction",
"Defect removal",
"Swirl mark removal",
"Scratch correction",
"Clear coat restoration",
"Polishing techniques",
"Paint leveling",
"Buffing process",
"Microfiber",
"Polishing",
"Surface refinement",

"Car AC care",
"air conditioning maintenance",
"AC cleaning",
"Car AC cooling issues",
"Car AC odor removal",
"Car AC efficiency",
"Car AC preventive maintenance",
"Car AC Gas Filling",
"AC duct cleaning",
"Air conditioning maintenance",
"Duct sanitization",
"Air vent cleaning",
"Odor removal",
"Dust removal",
"Air quality improvement",
"AC filter cleaning",
"Ventilation system cleaning",
"Fungal removal",
"Airflow optimization",
"Deep cleaning air ducts",
"Professional AC cleaning",
"Ac evaporation coil cleaning",
"Evaporator coil cleaning",
"AC coil maintenance",
"Coil sanitization",
"Dust and debris removal",
"Condensate drain cleaning",
"Improved airflow",
"Air quality enhancement",
"AC system efficiency",
"Professional coil cleaning",
"Foaming coil cleaner",
"Car AC Efficiency enhancer",
"Air conditioning repair",
"Cooling system cleaning",

"Underbody rust protection",
"Anti-rust coating",
"Undercoating treatment",
"Rustproofing",
"Corrosion prevention",
"Protective undercoating",
"Chassis rust protection",
"Spray-on rust treatment",
"Automotive underbody coating",
"Underbody maintenance",
"Long-lasting rust protection",
"Rust barrier application",
"Professional underbody coating",
"Silencer coating",
"Exhaust coating",
"Thermal barrier coating",
"Rust protection for exhaust",
"Exhaust heat shield",
"Anti-corrosion exhaust coating",
"Exhaust pipe insulation",
"Performance exhaust coating",
"Exhaust system protection",
"Exhaust system maintenance",
"Internal panel rust protection",
"Anti-rust coatings",
"Corrosion inhibitor",
"Rustproofing treatment",
"Undercoating for panels",
"Preventive rust coating",
"Automotive rust protection",
"Spray-on anti-rust coating",
"Sound deadening rust coating",
"Protective barrier for metal panels",
"Long-lasting rust prevention",
"Durable interior panel protection",
"Professional rust-proofing service",
"Anti-corrosion treatment for vehicles",
"Panel anti rust coating",

"car engine care",
"Car engine service",
"Engine care",
"Engine maintenance", 
"Car engine repair",
"Engine cleaning",
"Car engine overheating",
"Engine performance enhancement",
"Engine carbon cleaning",
"Car engine protection",
"Car engine performance",
"Engine lubrication",
"Car engine check-up",
"Engine dressing",
"Professional engine detailing",
"Engine detailing",
"Glossy engine finish",
"Engine compartment dressing",
"Engine surface protectant",
"Engine restoration",
"Engine shine",
"Engine lacquer coating",
"Professional engine coatings",
"High-temperature lacquer",
"Heat-resistant coating",
"Engine paint",
"Chemical-resistant engine paint",
"Protective engine lacquer",
"Automotive engine coatings",
"Durable engine paint",
"Heat-resistant dressing",
"Gloss engine finish",
"Engine restoration coating",
"Anti-corrosion engine lacquer",
"Engine enamel",
"Engine surface protection",
"Spray-on engine lacquer",
"Engine block coating",
"Engine component refinishing",
"Engine lacquer application",
"Rodent repellent spray",
"Engine compartment protection",
"Rodent deterrent",
"Animal repellent",
"Pest control",
"Non-toxic rodent repellent",
"Rodent barrier",
"Vehicle engine protection",
"Mouse repellent",
"Long-lasting rodent protection",
"Odorless rodent repellent",
"Automotive pest control",


"Car AC care",
"air conditioning maintenance",
"AC cleaning",
"Car AC cooling issues",
"Car AC odor removal",
"Car AC efficiency",
"Car AC preventive maintenance",
"Car AC Gas Filling",
"AC duct cleaning",
"Air conditioning maintenance",
"Duct sanitization",
"Air vent cleaning",
"Odor removal",
"Dust removal",
"Air quality improvement",
"AC filter cleaning",
"Ventilation system cleaning",
"Fungal removal",
"Airflow optimization",
"Deep cleaning air ducts",
"Professional AC cleaning",
"Ac evaporation coil cleaning",
"Evaporator coil cleaning",
"AC coil maintenance",
"Coil sanitization",
"Dust and debris removal",
"Condensate drain cleaning",
"Improved airflow",
"Air quality enhancement",
"AC system efficiency",
"Professional coil cleaning",
"Foaming coil cleaner",
"Car AC Efficiency enhancer",
"Air conditioning repair",
"Cooling system cleaning",

"Headlamp restoration",
"Professional headlamp service",
"Long-lasting headlight protection",
"Headlight cleaning",
"Headlight polishing",
"Headlamp clarity enhancement",
"Cloudy headlight removal",
"Foggy lens restoration",
"Lens restoration",
"Headlight refurbishment",
"Clear lens treatment",

"Car exterior wash",
"Car wash",
"Pressure wash",
"Microfiber cloth wash",
"Car shampoo",
"Rinse-off",
"Hard water spots removal",
"Car Cleaning",
"Car deep cleaning",
"Top wash",
"Top body wash",
"High Pressure Car wash",
"Foam Car wash",
"Full Car wash",
"Deep Car Wash",
"Car Foam Wash",
"Car Interior and Exterior wash",
"Car Vacuuming and Cleaning",

"interior car wash",
"Interior washing",
"Vacuuming",
"Dashboard cleaning",
"Upholstery cleaning",
"Leather conditioner",
"Carpet cleaning",
"Fabric cleaner",
"Stain removal",
"Odor elimination",
"Air vent cleaning",
"Interior protectant",
"Seat cleaning",
"Glass cleaner",
"Door panel cleaning",
"Pet hair removal",

  ];

  useEffect(() => {
    if (!hasSlid) {
      const interval = setInterval(() => {
        setActiveSection((prevSection) => {
          const nextSection = (prevSection + 1) % totalSections;
          if (nextSection === 0) {
            clearInterval(interval);
            setHasSlid(true);
          }
          return nextSection;
        });
      }, 5000);

      return () => clearInterval(interval);
    }
  }, [hasSlid, totalSections]);

  const handleSectionChange = (sectionIndex) => {
    setActiveSection(sectionIndex);
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const selectLocation = (loc) => {
    setLocation(loc);
    setIsDropdownOpen(false);
  };

  const handleChangeQuery = (e) => {
    const value = e.target.value;
    setQuery(value);

    if (value) {
      const results = items.filter(item =>
        item.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredItems(results);
      setShowSuggestions(true);
      setHighlightedIndex(-1);
    } else {
      setShowSuggestions(false);
      setFilteredItems([]);
    }
  };

  const handleSearch = () => {
    if (!query) return;
  
    const results = items.filter((item) =>
      item.toLowerCase().includes(query.toLowerCase())
    );
    console.log("Search Results: ", results);

    setFilteredItems(results);
    setShowSuggestions(false);
  
    if (results.some((result) =>
      ["CAR WASH",
      "Car exterior wash",
"Car wash",
"Pressure wash",
"Microfiber cloth wash",
"Car shampoo",
"Rinse-off",
"Hard water spots removal",
"Car Cleaning",
"Car deep cleaning",
"Top wash",
"Top body wash",
"High Pressure Car wash",
"Foam Car wash",
"Full Car wash",
"Deep Car Wash",
"Car Foam Wash",
"Car Interior and Exterior wash",
"Car Vacuuming and Cleaning",
"interior car wash",
"Interior washing",
"Vacuuming",
"Dashboard cleaning",
"Upholstery cleaning",
"Leather conditioner",
"Carpet cleaning",
"Fabric cleaner",
"Stain removal",
"Odor elimination",
"Air vent cleaning",
"Interior protectant",
"Seat cleaning",
"Glass cleaner",
"Door panel cleaning",
"Pet hair removal",
].includes(result)
    )) {
      console.log("Navigating to /foamwash"); // Debugging line
      navigate("/foamwash");
    } 
    else if (
      results.some((result) =>
        [
          "Paint Protection Films (PPF)",
          "Wraps and Films",
          "Car Paint Lamination",
          "Car Wrap Protection",
          "Self-Healing Film",
          "Car Paint Protection Films",
          "Scratch Resistance",
          "Stone Chip Protection",
          "Hydrophobic Layer",
          "Top Coat Protection",
          "Full Body PPF",
          "Custom PPF",
          "Professional PPF",
          "Corrosion Protection",
          "Scratch Resistance Film/Layer",
          "Car Surface Protection film",
          "Car Exterior Protection film",
        ].includes(result)
      )
    ) {
      console.log("Navigating to /paintprotectionfilm");
      navigate("/paintprotectionfilm");
    } else if (
      results.some((result) =>
        [
          "Ceramic Coating",
          "Nano-ceramic coating",
          "Hydrophobic coating",
          "Graphene Coating",
          "Gloss enhancement",
          "UV protection",
          "Long-lasting shine",
          "Professional ceramic coating",
          "Surface durability",
          "Swirl mark prevention",
          "Deep paint gloss",
          "Fade-resistant finish",
        ].includes(result)
      )
    ) {
      console.log("Navigating to /ceramiccoating");
      navigate("/ceramiccoating");
    } 
   else if (
      results.some((result) =>
        ["Interior car care",
"Car interior cleaning ",
"Car interior detailing",
"Car interior restoration",
"Car interior maintenance",
"Deep cleaning car interior",
"Interior car care products",
"Stain removal ",
"Car interior disinfectant",

"Economic interior cleaning",
"Budget interior cleaning",
"Affordable car interior cleaning",
"Inexpensive interior cleaning ",
"Low-cost interior cleaning",
"Cost-effective interior cleaning", 
"Basic car interior cleaning",
"Minimalist car interior cleaning", 
"Simple interior cleaning ",
"Basic maintenance interior cleaning", 

"Foam based interior cleaning",
"Foam cleaner",
"Leather cleaner",

"Antimicrobial car cleaner",
"Germ-killing", 
"Antibacterial",
"Bacteria removal", 
"Disinfectant",
"Odor and germ eliminator",
"germ protection",
"Germ-resistant",
"Antimicrobial",
"Sanitizing",

"Deep cleaning",
"Deep interior",
"Thorough Cleaning",
"Full car Cleaning", 
"Complete interior", 
"Intensive Cleaning", 
"Professional cleaning",
].includes(result)
)
) {
  console.log("Navigating to /interiortreatment");
      navigate("/interiortreatment");
}
else if (
  results.some((result) =>
    [ "exterior care",
"Car UV paint protection",
"UV paint protection",
"UV shield",
"UV-resistant",
"Sun damage prevention",
"sun protection",
"Paint fading prevention",
"Long-lasting UV protection",
"UV blocker", 
"Teflon Coating",
"Ticket polish",
"Paint correction",
"Surface polishing",
"Scratch removal",
"Swirl mark elimination",
"Paint finishing",
"Paint enhancement",
"Deep scratch repair",
"Professional paint correction",
"Polishing",
"Car surface refinement",
"Surface refinement",
"Paint enhancement",
"Gloss restoration",
"Surface smoothing",
"Surface decontamination",
"Surface preparation",
"Finish enhancement",
"Deep shine",
"Refined gloss finish",
"Surface correction",
"Defect removal",
"Swirl mark removal",
"Scratch correction",
"Clear coat restoration",
"Polishing techniques",
"Paint leveling",
"Buffing process",
"Microfiber",
"Polishing",
"Surface refinement",
 ].includes(result)
)
) {
  console.log("Navigating to /exteriortreatment");
   navigate("/exteriortreatment");
   } 
   else if (
    results.some((result) =>
      [ 
        "Car AC care",
        "air conditioning maintenance",
        "AC cleaning",
        "Car AC cooling issues",
        "Car AC odor removal",
        "Car AC efficiency",
        "Car AC preventive maintenance",
        "Car AC Gas Filling",
        "AC duct cleaning",
        "Air conditioning maintenance",
        "Duct sanitization",
        "Air vent cleaning",
        "Odor removal",
        "Dust removal",
        "Air quality improvement",
        "AC filter cleaning",
        "Ventilation system cleaning",
        "Fungal removal",
        "Airflow optimization",
        "Deep cleaning air ducts",
        "Professional AC cleaning",
        "Ac evaporation coil cleaning",
        "Evaporator coil cleaning",
        "AC coil maintenance",
        "Coil sanitization",
        "Dust and debris removal",
        "Condensate drain cleaning",
        "Improved airflow",
        "Air quality enhancement",
        "AC system efficiency",
        "Professional coil cleaning",
        "Foaming coil cleaner",
        "Car AC Efficiency enhancer",
        "Air conditioning repair",
        "Cooling system cleaning",  ].includes(result)
  )
  ) {
    console.log("Navigating to /airconditioner");
     navigate("/airconditioner");
     } 
     else if (
      results.some((result) =>
        [   "car engine care",
          "Car engine service",
          "Engine care",
          "Engine maintenance", 
          "Car engine repair",
          "Engine cleaning",
          "Car engine overheating",
          "Engine performance enhancement",
          "Engine carbon cleaning",
          "Car engine protection",
          "Car engine performance",
          "Engine lubrication",
          "Car engine check-up",
          "Engine dressing",
          "Professional engine detailing",
          "Engine detailing",
          "Glossy engine finish",
          "Engine compartment dressing",
          "Engine surface protectant",
          "Engine restoration",
          "Engine shine",
          "Engine lacquer coating",
          "Professional engine coatings",
          "High-temperature lacquer",
          "Heat-resistant coating",
          "Engine paint",
          "Chemical-resistant engine paint",
          "Protective engine lacquer",
          "Automotive engine coatings",
          "Durable engine paint",
          "Heat-resistant dressing",
          "Gloss engine finish",
          "Engine restoration coating",
          "Anti-corrosion engine lacquer",
          "Engine enamel",
          "Engine surface protection",
          "Spray-on engine lacquer",
          "Engine block coating",
          "Engine component refinishing",
          "Engine lacquer application",
          "Rodent repellent spray",
          "Engine compartment protection",
          "Rodent deterrent",
          "Animal repellent",
          "Pest control",
          "Non-toxic rodent repellent",
          "Rodent barrier",
          "Vehicle engine protection",
          "Mouse repellent",
          "Long-lasting rodent protection",
          "Odorless rodent repellent",
          "Automotive pest control",
            ].includes(result)
    )
    ) {
      console.log("Navigating to /engineprotection");
       navigate("/engineprotection");
       } 
       else if (
        results.some((result) =>
          [
            "Headlamp restoration",
"Professional headlamp service",
"Long-lasting headlight protection",
"Headlight cleaning",
"Headlight polishing",
"Headlamp clarity enhancement",
"Cloudy headlight removal",
"Foggy lens restoration",
"Lens restoration",
"Headlight refurbishment",
"Clear lens treatment",

 ].includes(result)
      )
      ) {
        console.log("Navigating to /headlamprestoration");
         navigate("/headlamprestoration");
         } 
           else if (
          results.some((result) =>
            ["Windshield restoration",
"Professional glass restoration",
"Windshield cleaning",
"Windshield resurfacing",
"Glass polishing",
"Scratch removal",
"Clarity restoration",
"Water spot removal",
"Haze removal",
"Glass treatment",
"glass maintenance",
"Hard water Mark removal", 
"Hard water removal",
"Calcium deposition removal",
"Glass Stain removal",
   ].includes(result)
        )
        ) {
          console.log("Navigating to /Windshield Restoration");
          navigate("/Windshield Restoration");
          } 
          else if (
            results.some((result) =>
              [  "Underbody rust protection",
"Anti-rust coating",
"Undercoating treatment",
"Rustproofing",
"Corrosion prevention",
"Protective undercoating",
"Chassis rust protection",
"Spray-on rust treatment",
"Automotive underbody coating",
"Underbody maintenance",
"Long-lasting rust protection",
"Rust barrier application",
"Professional underbody coating",
"Silencer coating",
"Exhaust coating",
"Thermal barrier coating",
"Rust protection for exhaust",
"Exhaust heat shield",
"Anti-corrosion exhaust coating",
"Exhaust pipe insulation",
"Performance exhaust coating",
"Exhaust system protection",
"Exhaust system maintenance",
"Internal panel rust protection",
"Anti-rust coatings",
"Corrosion inhibitor",
"Rustproofing treatment",
"Undercoating for panels",
"Preventive rust coating",
"Automotive rust protection",
"Spray-on anti-rust coating",
"Sound deadening rust coating",
"Protective barrier for metal panels",
"Long-lasting rust prevention",
"Durable interior panel protection",
"Professional rust-proofing service",
"Anti-corrosion treatment for vehicles",
"Panel anti rust coating",
 ].includes(result)
          )
          ) {
            console.log("Navigating to /antirustprotection");
            navigate("/antirustprotection");
            } 
else {
      // Optionally handle if no matching route is found
      console.log("No matching service found.");
    }
  };
  const handleKeyDown = (e) => {
    if (e.key === 'ArrowDown') {
      setHighlightedIndex((prevIndex) => Math.min(prevIndex + 1, filteredItems.length - 1));
      e.preventDefault();
  } else if (e.key === "ArrowUp") {
      setHighlightedIndex((prevIndex) => Math.max(prevIndex - 1, 0));
      e.preventDefault();
    } else if (e.key === 'Enter') {
      if (highlightedIndex >= 0 ) {
        handleSuggestionClick(filteredItems[highlightedIndex]);
      } else {
        handleSearch();
      }
    }
  };

  const handleSuggestionClick = (suggestion) => {
    setQuery(suggestion);
    setShowSuggestions(false);
    handleSearch(); 
    setHighlightedIndex(-1);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsDropdownOpen(false);
    }
  };
  const closeSuggestions = (e) => {
    if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
      setShowSuggestions(false);
    }
  };


  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  useEffect(() => {
    document.addEventListener('mousedown', closeSuggestions);
    return () => {
      document.removeEventListener('mousedown', closeSuggestions);
    };
  }, []);

  return (
    <div className="homepage">
      <div className="home-bg">
        <div className="hero-content">
          <h1 className="animated-heading" style={{ fontFamily: 'Poppins,, sans-serif' }}>
            CAR CARE, ANYWHERE!
          </h1>
          <p className="animated-paragraph" style={{ fontFamily: 'Poppins,, sans-serif',textAlign:'center'}}>
            Because your car deserves the best care,</p>
            <p className="animated-paragraph"style={{textAlign:'center'}} > Wherever you are!"
          </p>
      
      <div className="search-container" ref={dropdownRef}>
        <input
          type="text"
          className="search-input"
          placeholder="Search for services..."
          value={query}
          onChange={handleChangeQuery}
          onKeyDown={handleKeyDown}         
        />

        <button className="search-button" onClick={handleSearch}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <circle cx="11" cy="11" r="8"></circle>
            <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
          </svg>
        </button>

        {showSuggestions && filteredItems.length > 0 && (
          <ul className="suggestions-list">
            {filteredItems.map((item, index) => (
              <li key={index}
              className={index === highlightedIndex ? 'highlighted' : ''}
              onClick={() => handleSuggestionClick(item)}
              >
                {item}
              </li>
            ))}
          </ul>
        )}
      </div>   </div>
      </div>
      <CarInfo />
      <Services />
    </div>
  );
}

export default Homepage;
