import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import statuscheck from '../image/satuschack.png';      // Inactive icon
import statuscheckfill from '../image/satuschackfill.png'; // Active icon

const StatusSidebar = () => {
  const [activeStep, setActiveStep] = useState(1);
  const location = useLocation();

  useEffect(() => {
    // Update the step based on the current route
    switch (location.pathname) {
      case '/ServiceListing':
        setActiveStep(2);
        break;
      case '/CustomerDetails':
        setActiveStep(2);
        break;
      case '/PaymentDetails':
        setActiveStep(3);
        break;
      // case '/PaymentConfirmation':
      //   setActiveStep(4);
      //   break;
      case '/BookingSummary':
        setActiveStep(5);
        break;
      default:
        setActiveStep(2);
    }
  }, [location.pathname]);

  // Function to get the icon source based on the step
  const getIconSrc = (step) => (step <= activeStep ? statuscheckfill : statuscheck);

  return (
    <div className="Status-sidebar">
      <h3>Customer Details</h3>
      <ul>
        <li style={{ color: activeStep >= 1 ? '#FFBE00' : '#FFFFFF', display: 'flex', alignItems: 'center',gap:'16px'}}>
          <img src={getIconSrc(1)} alt="status icon" /> Service Selection
        </li>
        <li style={{ color: activeStep >= 2 ? '#FFBE00' : '#FFFFFF', display: 'flex', alignItems: 'center',gap:'16px' }}>
          <img src={getIconSrc(2)} alt="status icon" /> Service Provider Selection
        </li>
        <li style={{ color: activeStep >= 3 ? '#FFBE00' : '#FFFFFF', display: 'flex', alignItems: 'center',gap:'16px' }}>
          <img src={getIconSrc(3)} alt="status icon" /> Customer Details
        </li>
        <li style={{ color: activeStep >= 4 ? '#FFBE00' : '#FFFFFF', display: 'flex', alignItems: 'center',gap:'16px' }}>
          <img src={getIconSrc(4)} alt="status icon" /> Payment Confirmation
        </li>
        <li style={{ color: activeStep >= 5 ? '#FFBE00' : '#FFFFFF', display: 'flex', alignItems: 'center',gap:'16px' }}>
          <img src={getIconSrc(5)} alt="status icon" /> Booking Summary
        </li>
      </ul>
    </div>
  );
};

export default StatusSidebar;
