import React, { useState, useEffect, useRef  } from "react";
import "./Navbar.css";
import logo from "../image/LOGO-03.png";
import {Link } from 'react-router-dom'
import { FaBars, FaTimes, FaChevronDown, FaChevronLeft ,FaChevronUp } from "react-icons/fa";
import { faLocationDot,faCircleDot} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import locicon from "../image/Vector (1).png";
import loc from '../image/bhopalloc.png';

const Navbar = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [activeNestedDropdown, setActiveNestedDropdown] = useState(null);
  const dropdownRef = useRef(null);
  const [location, setLocation] = useState("Select");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);




  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const toggleDropdown = (dropdownId) => {
    setActiveDropdown(activeDropdown === dropdownId ? null : dropdownId);
    setActiveNestedDropdown(null); 
  };
  const toggleDropdownloc = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };
  
  const toggleNestedDropdown = (nestedDropdownId) => {
    setActiveNestedDropdown(
      activeNestedDropdown === nestedDropdownId ? null : nestedDropdownId
    );
  };

  const handleClickOutside = (e) => {
    const sidebar = document.querySelector('.mobile-sidebar');
    if (sidebar && !sidebar.contains(e.target)) {
      setIsMobileMenuOpen(false);
    }
  };
  const handleLinkClick = () => {
    setIsMobileMenuOpen(false); 
  };

  const selectLocation = (loc) => {
    setLocation(loc);
    setIsDropdownOpen(false);
  };

  useEffect(() => {
    if (isMobileMenuOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isMobileMenuOpen]);

  return (
    <div className="header">
    <div className="navbar-ajs">
      <div className="navbar-logo-ajs">
        <a href="/"><img src={logo} alt="Logo" /></a>
        <a href="/" style={{ fontFamily: '"Chakra Petch", sans-serif' }}>AutoKaar</a>
      </div>

      {/* Desktop Menu */}
      <ul className="desktop-menu-web">
        <li className="dropdown-web">
          <Link to="/">Home</Link>
        </li>
        <li className="dropdown-web">
          <Link to="#" onClick={() => toggleDropdown("About")} >
          About{" "}
         </Link>  
          {activeDropdown === "services" && (
            <ul className="sub-menu-web">
              <li className="sub-item-web">
              <Link
                    to="/about"
                    onClick={() => toggleNestedDropdown("foam-wash")}
                  >
                    About{" "}
                  </Link>
                  </li>
                  <li className="sub-item-web">
              <Link
                    to="/"
                    onClick={() => toggleNestedDropdown("foam-wash")}
                  >
                    HOW to Book a Series{" "}
                  </Link>
                  </li>
                  <li className="sub-item-web">
              <Link
                    to="/faq"
                    onClick={() => toggleNestedDropdown("foam-wash")}
                  >
                    Faq{" "}
                  </Link>
                  </li> </ul>)} 
        </li>
        <li className="dropdown-web">
          <Link to="#" onClick={() => toggleDropdown("services")} >
             Service{" "}
         </Link>  
          {activeDropdown === "services" && (
            <ul className="sub-menu-web">
              <li className="sub-item-web">
              <Link
                    to="/foamwash"
                    onClick={() => toggleNestedDropdown("foam-wash")}
                  >
                    Wash{" "}
                  </Link>
              </li>
              <li className="sub-item-web">
                <Link
                  to="/paintprotectionfilm"
                  onClick={() => toggleNestedDropdown("paint-protection")}
                >
                  Paint Protection Films (PPF){" "}  </Link>
              </li>
              <li className="sub-item-web">
                <Link
                  to="/ceramiccoating"
                  onClick={() => toggleNestedDropdown("ceramic-coating")}
                >
                  Ceramic Coating{" "}
                </Link>
               
              </li>
              <li className="sub-item-web">
                <Link
                  to ="/interiortreatment"
                  onClick={() => toggleNestedDropdown("interior-treatment")}
                >
                  Interior Treatments{" "}
                </Link>
              </li>
              <li className="sub-item-web">
                <Link
                  to="/exteriortreatment"
                  onClick={() => toggleNestedDropdown("exterior-treatment")}
                >
                  Exterior Treatments {" "}
                </Link>
              </li>
              <li className="sub-item-web">
                <Link
                  to="/airconditioner"
                  onClick={() => toggleNestedDropdown("airconditioner")}
                >
                  AC Care {" "}
                </Link>
              </li>
              <li className="sub-item-web">
                <Link
                  to="/antirustprotection"
                  onClick={() => toggleNestedDropdown("antirustprotection")}
                >
                  Antirust Coatings {" "}
                </Link>
              </li>
              <li className="sub-item-web">
                <Link
                  to="/engineprotection"
                  onClick={() => toggleNestedDropdown("engineprotection")}
                >
                  Engine Protection {" "}
                </Link>
              </li>
              <li className="sub-item-web">
                <Link
                  to="/windshieldrestoration"
                  onClick={() => toggleNestedDropdown("windshieldrestoration")}
                >
                  Windshield Restoration {" "}
                </Link>
              </li>

              <li className="sub-item-web">
                <Link 
                  to ="/headlamprestoration"
                  onClick={() => toggleNestedDropdown("headlamprestoration")}
                >
                  Headlamp Restoration{" "}
                </Link>
              </li>            
            </ul>
          )}
        </li>
        <li className="dropdown-web">
          <Link to="/become-a-partner">Become a partner </Link>
        </li>       
      </ul>
      <div className="xyz">
  <div
    className="location-dropdown"
    onClick={toggleDropdownloc}
    ref={dropdownRef}
  >
    <span className="location-icon">
      <img src={loc} />
    </span>
    <span className="location-text" >
      {location}
    </span>
    {isDropdownOpen && (
      <div className="dropdown-menu" >
        {["Bhopal", "Indore", ].map((loc) => (
          <div key={loc} className="dropdown-item">
            <label
              htmlFor={loc}
              onClick={() => selectLocation(loc)}
              className="location-label"
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <span className="location-text">
          {loc}
        </span>
              <span className="icon-right">
              <img src={locicon} alt="" /> </span>
            </label>
          </div>
        ))}
      </div>
    )}
  </div>

  <li className="dropdown-web">
    <Link to="/bookanappointment">
      <button className="navbar-contact-ajs" style={{ marginLeft: '10px', marginRight: '30px' }}>
        Enquiry
      </button>
    </Link>
  </li>
</div>
<div className="mobile-menu-icon-ajs" onClick={toggleMobileMenu}>
        {isMobileMenuOpen ? <FaTimes /> : <FaBars />}
      </div>
      {/* Sidebar for mobile */}
      <div className={`mobile-sidebar ${isMobileMenuOpen ? "open" : ""}`}>
        <ul className="navbar-links-ajs mobile-menu">
          <li style={{ fontFamily: '"Chakra Petch", sans-serif' }}>
            <Link to="/">Home</Link >
          </li>
          <li>
            <Link to="/about"onClick={handleLinkClick}>About</Link >
          </li>
          <li className="dropdown-ajs" style={{ fontFamily: '"Chakra Petch", sans-serif' }}>
            <Link to="#" onClick={() => toggleDropdown("services")}>
              Service{" "}
              </Link>
            {activeDropdown === "services" && (
              <ul className="dropdown-menu-ajs">
                <li className="dropdown-item-ajs">
                <Link
                    to="/foamwash"
                    onClick={() => toggleNestedDropdown("foamwash")}
                  >
                  Wash{" "}
                  </Link>
                </li>
                <li className="dropdown-item-ajs">
                  <Link
                    to="/paintprotectionfilm"
                    onClick={() => toggleNestedDropdown("paint-protection")}
                  >
                    Paint Protection Films (PPF){" "}
                  </Link>
                </li>
                <li className="dropdown-item-ajs">
                  <Link
                    to="/ceramiccoating"
                    onClick={() => toggleNestedDropdown("ceramic-coating")}
                  >
                    Ceramic Coating{" "}
                  </Link>
                </li>
                <li className="dropdown-item-ajs">
                  <Link
                    to="/interiortreatment"
                    onClick={() => toggleNestedDropdown("interior-treatment")}
                  >
                    Interior Treatments {" "}
                  </Link>
                </li>
                <li className="dropdown-item-ajs">
                  <Link
                    to="/exteriortreatment"
                    onClick={() => toggleNestedDropdown("exterior-treatment")}
                  >
                    Exterior Treatments {" "}
                  </Link>
                </li>
                <li className="dropdown-item-ajs">
                <Link
                  to="/airconditioner"
                  onClick={() => toggleNestedDropdown("airconditioner")}
                >
                  AC Care{" "}
                </Link>
              </li>
              <li className="dropdown-item-ajs">
                <Link
                  to="/antirustprotection"
                  onClick={() => toggleNestedDropdown("antirustprotection")}
                >
                  Antirust Coatings{" "}
                </Link>
                </li>                   
              <li className="dropdown-item-ajs">
                <Link
                  to="/engineprotection"
                  onClick={() => toggleNestedDropdown("engineprotection")}
                >
                  Engine Protection{" "}
                </Link>
              </li>
              <li className="dropdown-item-ajs">
                <Link
                  to="/windshieldrestoration"
                  onClick={() => toggleNestedDropdown("windshieldrestoration")}
                >
                  Windshield Restoration {" "}
                </Link>
              </li>
              <li className="dropdown-item-ajs">
                <Link
                  to="/headlamprestoration"
                  onClick={() => toggleNestedDropdown("headlamprestoration")}
                >
                  Headlamp Restoration {" "}
                </Link>
              </li>
              </ul>
            )}
          </li>
          <li style={{ fontFamily: '"Chakra Petch", sans-serif' }}>
            <Link to="/become-a-partner"onClick={handleLinkClick}>Become a Partner</Link>
          </li>
          <li>
         <Link to="/bookanappointment"onClick={handleLinkClick}> <button className="navbar-contact-ajs" style={{ fontFamily: '"Chakra Petch", sans-serif' }}>Enquiry</button></Link>
        </li>
        </ul>
      </div>
    </div>
    </div>
  );
};

export default Navbar;
