import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
import "./faq.css";

const FAQ = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const faqs = [
    {
      question: '1.What is AutoKaar?',
      answer:'AutoKaar is an all-in-one platform that connects customers with a wide network of professional car detailing studios. Our platform allows you to easily browse, compare, and book car care services at the best detailing studios in your area with just one click.',
     
    },
    {
      question: '2.What problem is AutoKaar helping me address?',
      answer: (
        <div>
          AutoKaar is a comprehensive platform that connects customers with a wide network of professional car detailing studios in nearby locations. It addresses issues such as:
          <ul>
            <li>Ensuring quality assurance for the price paid</li>
            <li>Providing predefined pricing</li>
            <li>Minimizing waiting times</li>
            <li>Monitoring product usage</li>
            <li>Facilitating conflict resolution</li>
            <li>Ensuring transparency throughout every step of the process</li>
          </ul>
        </div>
      ),    },
    {
      question: '3.What types of services can I book through AutoKaar?',
      answer: (
        <div>
         You can book a wide range of car detailing services, including:
                   <ul>
            <li>Exterior and interior cleaning</li>
            <li>Washing</li>
            <li>Ceramic coating</li>
            <li>Paint protection film (PPF)</li>
            <li>Upholstery cleaning</li>
            <li>Engine detailing</li>
            <li>Headlight restoration</li>
            <li>and Many More</li>
          </ul>
        </div>
      ),  
    },
    {
      question: '4.How do I find car detailing studios near me?',
      answer: 'Simply enter your location or enable location access for AutoKaar. Our platform will show you a list of nearby detailing studios, including their ratings, reviews, and service prices. You can filter the results by service type, price range, or customer rating.'
    },
    {
      question: '5.How do I book an appointment?',
      answer: 'Enter your budget, and our platform will display tailored results. Choose a service provider by location or service type, filtering by exterior, interior, or full car detailing. Compare prices, read reviews, and select the best fit. Book your appointment, confirm details, and enjoy secure payment. A driver will pick up your car only after you share OTP with him.',
    },
    {
      question: '6.Can I cancel or reschedule my appointment?',
      answer: 'Yes, you can cancel or reschedule your appointment through your AutoKaar account. Just go to your bookings and follow the prompts to modify or cancel the appointment, subject to the studio’s cancellation policy.'
    },
    {
      question: '7.How do I pay for the services?',
      answer: 'AutoKaar offers multiple payment options, including UPI, credit/debit cards, mobile wallets, and online banking. You can pay securely through the platform during the booking process.'
    },
    {
      question: '8.Does AutoKaar offer any discounts or promotions?',
      answer: 'Yes, AutoKaar frequently offers exclusive discounts and promotions in collaboration with our partner studios. Keep an eye on our promotions section or subscribe to our newsletter to stay updated.'
    },
    {
      question: '9. How are the detailing studios rated on AutoKaar?',
      answer: 'Studios on AutoKaar are rated based on customer reviews and service quality. After completing a service, customers are encouraged to leave reviews and rate their experience, which helps others make informed choices.'
    },
    {
      question: '10. Can I trust the quality of services offered by the detailing studios?',
      answer: 'Yes, we partner only with verified and professional car detailing studios. Each studio undergoes a strict vetting process to ensure they meet our quality and customer service standards.'
    },
    {
      question: '11. Is there a mobile app for AutoKaar?',
      answer: 'Curetly we are taking orders through our Website only. But Yes, AutoKaar will make it available for both iOS and Android. You can easily browse, book, and manage your car detailing appointments on the go.'
    },
    {
      question: '12. What if I encounter an issue with my booking or service?',
      answer: 'If you face any issues with your booking or the service, you can contact AutoKaar customer support through the platform or app. We will work to resolve your issue promptly by coordinating with the service provider.'
    },
    {
      question: '13. Do I need an account to use AutoKaar?',
      answer: 'Yes, creating an account helps you track your bookings, manage appointments, and receive notifications about exclusive deals. Signing up is quick and free.'
    },
    {
      question: '14. How does AutoKaar ensure fair pricing?',
      answer: 'AutoKaar allows you to compare prices across multiple studios. We encourage transparency, and pricing details are listed upfront so you can make an informed decision before booking.'
    },
    {
      question: '15. How can I become a partner studio on AutoKaar?',
      answer: 'If you`re interested in listing your car detailing studio on AutoKaar, you can apply through our website. Our team will review your application and reach out to discuss partnership opportunities.'
    },
  ];

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index); // Toggle between open/close
  };

  return (
    <div className="faq-section">
      <h1>Frequently Asked Questions</h1>
      <div className="faq-list">
        {faqs.map((faq, index) => (
          <div key={index} className="faq-item">
            <div className="faq-question" onClick={() => toggleFAQ(index)}>
              <span>{faq.question}</span>
              <FontAwesomeIcon icon={activeIndex === index ? faMinus : faPlus} className="faq-icon" />
            </div>
            {activeIndex === index && (
              <div className="faq-answer">
                {faq.answer}
              </div>
            )}
          </div>
        ))}
      </div>
      <div className="still-have-question">
        <h2>Still Have A Question?</h2>
        <p>Contact customer support for more help & queries.</p>
        <a href="tel:+919981040082">+919981040082</a>
      </div>
    </div>
  );
};

export default FAQ;
