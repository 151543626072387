import React, { useState } from "react";
import "./part.css"; // Import the CSS file for styling
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTrash,
  faCloudUploadAlt,
  faPlus,
  faMinus,
  faArrowLeft,
  faArrowRight,
  faChevronDown
}
from '@fortawesome/free-solid-svg-icons'; // All from FontAwesome
import PartnerSidebar from './PartnerSidebar';

const ResponsiveLayout = () => {
  const [activeStep, setActiveStep] = useState(1);
  const [showForm, setShowForm] = useState(true);
  const [currentSection, setCurrentSection] = useState(1);
  const [formData, setFormData] = useState({
    companyName: '',
    businessType: '',
    registeredAddress: '',
    city: '',
    state: '',
    postalCode: '',
    otherService: '',
    website: '',
    registeredAddress1:'',
    OfficialMail:'',
    PrimaryContactPhone:'',
    storeclosed: 'sunday',
    otherBusinessType: '',
    yearEstablished: '',
    numEmployees: '',
    primaryContactName: '',
    primaryContactTitle: '',
    primaryContactEmail: '',
    primaryContactPhone: '',
    ContactPhone: '',
    altContactName: '',
    SecondaryPhone:'',
    altContactTitle: '',
    altContactEmail: '',
    altContactPhone: '',
    servicesOffered: '',
    serviceArea: '',
    serviceTurnaround: '',
    operatingHoursWeekdaysStart: '',
    operatingHoursWeekdaysEnd: '',
    operatingHoursWeekendsStart: '',
    operatingHoursWeekendsEnd: '',
    numServiceBays: '',
    equipmentAvailable: '',
    certifications: '',
    partnershipGoals: '',
    termsConditions: '',
    documentsRequired: '',
    additionalComments: '',
    signatoryName: '',
    signatoryTitle: '',
    signature: '',
    date: '',
    servicesOffered: [],
    otherService: '',
    additionalServices: [],
    state: "",
    city: "",
  });
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [errors, setErrors] = useState({});
  const [uploadedFiles, setUploadedFiles] = useState({});
  const [isOpen, setIsOpen] = useState({});
const [showAddService, setShowAddService] = useState(false); 
const [cities, setCities] = useState([]);
const [isChecked, setIsChecked] = useState(false);

const stateCityMapping = {
  MH: {
    state: "Maharashtra",
    cities: [
      "Mumbai",
      "Pune",
      "Nagpur",
      "Nashik",
      "Aurangabad",
      "Solapur",
      "Amravati",
      "Thane",
      "Kolhapur",
      "Nanded",
    ],
  },
  MP: {
    state: "Madhya Pradesh",
    cities: [
      "Indore",
      "Bhopal",
      "Gwalior",
      "Jabalpur",
      "Ujjain",
      "Ratlam",
      "Sagar",
      "Rewa",
      "Satna",
      "Dewas",
    ],
  },
};
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
    if (name === "state") {
      setCities(stateCityMapping[value]?.cities || []); // Set corresponding cities for the selected state
      setFormData((prevData) => ({
        ...prevData,
        city: "", // Reset city when state changes
      }));
    }
  };


  // Handle checkbox changes for services offered
  const handleServiceChange = (e) => {
    const { name, value, checked } = e.target;

    if (name === "servicesOffered") {
      setFormData((prevData) => ({
        ...prevData,
        servicesOffered: checked
          ? [...prevData.servicesOffered, value]
          : prevData.servicesOffered.filter((service) => service !== value)
      }));
    } else if (name === "otherService") {
      setFormData((prevData) => ({
        ...prevData,
        otherService: value
      }));
    }
  };

  // Add additional services
  const handleAddMore = () => {
    setIsChecked(!isChecked); 
    if (formData.otherService) {
      setFormData((prevData) => ({
        ...prevData,
        additionalServices: [...prevData.additionalServices, prevData.otherService],
        otherService: ''
      }));
    }
  };

  const handleNext = () => {
    let currentErrors = {};
    // Validate section 1 (Company Information)
    if (currentSection === 2) {
      if (!formData.companyName) currentErrors.companyName = 'Company Name is required';
      if (!formData.PrimaryContactPhone) currentErrors.PrimaryContactPhone = 'Primary Contact No. is required';
      if (!formData.SecondaryPhone) currentErrors.SecondaryPhone = 'Secondary No is required';
      if (!formData.businessType) currentErrors.businessType = 'Business Type is required';
      if (!formData.registeredAddress) currentErrors.registeredAddress = 'Address is required';
      if (!formData.registeredAddress1) currentErrors.registeredAddress1 = 'Address is required';
      if (!formData.city) currentErrors.city = 'City is required';
      if (!formData.state) currentErrors.state = 'State is required';
      if (!formData.postalCode) currentErrors.postalCode = 'Postal Code is required'; 
      if (!formData.OfficialMail) currentErrors.OfficialMail = 'Official Mail is required';
    }

    // Validate section 2 (Contact Information)
    if (currentSection === 1) {
      if (!formData.primaryContactName) currentErrors.primaryContactName = 'Name is required';
      if (!formData.primaryContactTitle) currentErrors.primaryContactTitle = 'Title is required';
      if (!formData.primaryContactEmail) currentErrors.primaryContactEmail = 'Email is required';
      if (!formData.primarywhatapp) currentErrors.primarywhatapp = 'whatsApp is required';
    }

    // Validate section 3 (Service Details)
    if (currentSection === 3) {
      if (!formData.servicesOffered) currentErrors.servicesOffered = 'Services Offered is required';
      if (!formData.serviceArea) currentErrors.serviceArea = 'Service Area is required';
      // if (!formData.serviceTurnaround) currentErrors.serviceTurnaround = 'Turnaround Time is required';
      // if (!formData.operatingHoursWeekdaysStart) currentErrors.operatingHoursWeekdaysStart = 'Weekday Start Time is required';
      // if (!formData.operatingHoursWeekdaysEnd) currentErrors.operatingHoursWeekdaysEnd = 'Weekday End Time is required';
      // if (!formData.operatingHoursWeekendsStart) currentErrors.operatingHoursWeekendsStart = 'Weekend Start Time is required';
      // if (!formData.operatingHoursWeekendsEnd) currentErrors.operatingHoursWeekendsEnd = 'Weekend End Time is required';
      if (!formData.numServiceBays) currentErrors.numServiceBays = 'Number of Service Bays is required';
      // if (!formData.equipmentAvailable) currentErrors.equipmentAvailable = 'Equipment Available is required';
      // if (!formData.certifications) currentErrors.certifications = 'Certifications is required';
    }

    // Validate section 4 (Partnership Goals and Terms)
    if (currentSection === 4) {
      // if (!formData.partnershipGoals) currentErrors.partnershipGoals = 'Partnership Goals are required';
      // if (!formData.additionalComments) currentErrors.additionalComments = 'Additional Comments are required';
    }

    if (Object.keys(currentErrors).length > 0) {
      setErrors(currentErrors); // Set errors to display
    } else {
      setErrors({}); // Clear errors
      setActiveStep((prevStep) => Math.min(prevStep + 1, 5)); // Update active step
  
      // Delay the update of current section
      setTimeout(() => {
        setCurrentSection((prevSection) => prevSection + 1); // Proceed to the next section
      }, 0); // The timeout ensures that setActiveStep takes effect first
    }
  };
  const handlePrevious = () => {
    setCurrentSection((prevSection) => Math.max(prevSection - 1, 1)); // Ensure section doesn't go below 1

  // Update the active step to go back
  setActiveStep((prevStep) => Math.max(prevStep - 1, 1)); // Ensure step doesn't go below 1

  // Optionally, clear any errors if you want to reset the state
  setErrors({});
};

  const handleSubmit = (e) => {
    e.preventDefault();

    // Make a POST request to the API with the form data
    fetch('http://localhost:5000/api/partners/add', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    })
      .then(response => {
        if (response.ok) {
          // Handle successful response
          setIsSubmitted(true);
          setShowSuccessMessage(true);
          setTimeout(() => {
            setShowSuccessMessage(false);
          }, 3000); // Hide the success message after 3 seconds

          // Optionally reset form data after successful submission
          setFormData({
            companyName: '',
            businessType: '',
            registeredAddress: '',
            city: '',
            state: '',
            postalCode: '',
            gstn: '',
            website: '',
            yearEstablished: '',
            numEmployees: '',
            otherBusinessType: '',
            primaryContactName: '',
            primaryContactTitle: '',
            primaryContactEmail: '',
            primaryContactPhone: '',
            ContactPhone: '',
            SecondaryPhone: '',
            PrimaryContactPhone:'',
            primarywhatapp: '',
            altContactName: '',
            altContactTitle: '',
            altContactEmail: '',
            altContactPhone: '',
            storeclosed: 'sunday',
            otherService: '',
            registeredAddress1:'',
            servicesOffered: '',
            serviceArea: '',
            OfficialMail:'',
            serviceTurnaround: '',
            operatingHoursWeekdaysStart: '',
            operatingHoursWeekdaysEnd: '',
            operatingHoursWeekendsStart: '',
            operatingHoursWeekendsEnd: '',
            numServiceBays: '',
            equipmentAvailable: '',
            certifications: '',
            partnershipGoals: '',
            termsConditions: '',
            documentsRequired: '',
            additionalComments: '',
            signatoryName: '',
            signatoryTitle: '',
            signature: '',
            date: ''
          });
          setCurrentSection(1); // Reset to the first section
        } else {
          // Handle errors
          console.error('Form submission failed');
        }
      })
      .catch(error => {
        console.error('Error:', error);
      });
  };

  const displayError = (field) => {
    return errors[field] && <span className="error">{errors[field]}</span>;
  };
  const handleFileChange = (e, category) => {
    const file = e.target.files[0];
    
    if (file) {
      const isJPG = file.type === 'image/jpeg';
      const fileSizeLimit = 5 * 1024 * 1024; 

      if (!isJPG) {
        alert("Only JPG files are allowed.");
        return;
      }

      if (file.size > fileSizeLimit) {
        alert("JPG file size should not exceed 5MB.");
        return;
      }
      setUploadedFiles({
        ...uploadedFiles,
        [category]: file
      });
    }
  };
  const toggleOpen = (category) => {
    setIsOpen({
      ...isOpen,
      [category]: !isOpen[category]
    });
  };

  const handleDelete = (category) => {
    setUploadedFiles({
      ...uploadedFiles,
      [category]: null
    });
    setIsOpen({
      ...isOpen,
      [category]: false
    });
  };

  const uploadCategories = [
    'Business License/Registration Certificate',
    'Proof Of Insurance',
    'List Of Services And Pricing',
    'Photos Of Facility (1 Inside & 1 Outside)',
    'Any Other Relevant Certifications Or Documents'
  ];

  return (
    <div className="container-part">
               <PartnerSidebar activeStep={activeStep} />      {showForm && (
        <div className="form-container-part">
          {isSubmitted && showSuccessMessage && (
            <p className="success-message-part">Form submitted successfully!</p>
          )}
          <form onSubmit={handleSubmit}>
            {currentSection === 1 && (
               <>
          <h3> CONTACT INFORMATION </h3>
               <div className="two">
               <div className="beInput">
                 <label>Partner Name <span>*</span></label>
                 <input
                   type="text"
                   name="primaryContactName"
                   placeholder="Enter Your Name"
                   value={formData.primaryContactName}
                   onChange={handleChange}
                   required
                 />
                 {displayError('primaryContactName')}
               </div>
               <div className="beInput">
                 <label>Position/Title <span>*</span></label>
                 <input
                   type="text"
                   placeholder="Enter Your Designation"
                   name="primaryContactTitle"
                   value={formData.primaryContactTitle}
                   onChange={handleChange}
                   required
                 />
                 {displayError('primaryContactTitle')}
               </div>
               </div>
               <div className="two">

               <div className="beInput">
                 <label>Email Address <span>*</span></label>
                 <input
                   type="email"
                   placeholder="xyz@gmail.com"
                   name="primaryContactEmail"
                   value={formData.primaryContactEmail}
                   onChange={handleChange}
                   required
                 />
                 {displayError('primaryContactEmail')}
               </div>
               <div className="beInput">
                 <label>WhatsApp Number <span>*</span></label>
                 <input
                   type="tel"
                   placeholder="0000-000-000"
                   name="primarywhatapp"
                   value={formData.primarywhatapp}
                   onChange={handleChange}
                   required
                 />
                 {displayError('primarywhatapp')}
               </div>
               </div>

               <div className="two">
               <div className="beInput">
                
                 <label>Contact No. </label>
                 <input
                   type="tel"                   
                   placeholder="0000-000-000"
                   name="primaryContactPhone"
                   value={formData.primaryContactPhone }
                   onChange={handleChange}
                   required
                 />
                 {displayError('primaryContactPhone')}
               </div>
               <div className="beInput">
                 <label>Alternate Contact No.</label>
                 <input
                   type="tel"                   
                   placeholder="0000-000-000"
                   name="altContactPhone"
                   value={formData.altContactPhone}
                   onChange={handleChange}
                   required
                 />
                  {displayError('altContactPhone')}
               </div>
               </div>
               <div className="next-page">

  <button className="submit-bt submit" type="button"onClick={handleNext}>
  Next <FontAwesomeIcon icon={faArrowRight} />
  </button>
</div>
             
             </>
            )}
            {currentSection === 2 && (
             <>
             <h3>COMPANY DETAILS </h3>
             <div className="two">
             <div className="beInput">
               <label>Company Name: <span>*</span></label>
               <input
                 type="text"
                 name="companyName"
                 placeholder="Enter Your Company Name "
                 value={formData.companyName}
                 onChange={handleChange}
                 required
               />
                 {displayError('companyName')}
             </div>
             <div className="beInput">
               <label>Primary Contact No <span>*</span></label>
               <input
                 type="tel"
                 name="PrimaryContactPhone"
                 placeholder="000-000-0000 "
                 value={formData.PrimaryContactPhone}
                 onChange={handleChange}
                 required
               />
                 {displayError('PrimaryContactPhone')}
                 </div>
                  </div>
                 <div className="two">
                 <div className="beInput"> 
                 <label>Business Type <span>*</span></label>
  <select
    name="businessType"
    className="custom-select"
    placeholder="Enter Your Designation"
    value={formData.businessType}
    onChange={handleChange}
    required
  >
    <option value="">Select...</option>
    <option value="Car Detailing Studio">Fanchise Model </option>
    <option value="Economical Car Wash Center">Car Detailling Studio </option>
    <option value="Local Detailing Studio">Multi-brand Workshop</option>
    <option value="Local Detailing Studio">Economical Car Wash Center </option>
    <option value="Other">Other (please specify)</option>
  </select>

  {displayError('businessType')}

  {/* Conditionally render input field when "Other" is selected */}
  {formData.businessType === 'Other' && (
    <div>
      <label>Others:</label>
      <input
        type="text"
        name="otherBusinessType"
        value={formData.otherBusinessType || ''}
        onChange={handleChange}
        placeholder="Give Description for Your Shop"
        required
      />
    </div>
  )}
</div>
<div className="beInput"> 

               <label>Secondary Contact No <span>*</span></label>
               <input
                 type="text"
                 name="SecondaryPhone"
                 placeholder="000-000-0000 "
                 value={formData.SecondaryPhone}
                 onChange={handleChange}
                 required
               />
                 {displayError('SecondaryPhone')}
             </div>
             </div>
             <div className="Webinput-container">
    <label htmlFor="website">Website Address</label>
    <input
        type="text"
        id="website"  // Added an id for accessibility
        placeholder="Enter Website url"
        name="website"
        value={formData.website}
        onChange={handleChange}
        required
        className="web-margin"
    />
</div>
             <div className="beInput">
               <label>Store Address-1 <span>*</span></label>
               <input
                 type="text"
                 name="registeredAddress"
                 value={formData.registeredAddress}
                 onChange={handleChange}
                 required
                 className="address-1"
                 />
               {displayError('registeredAddress')}
             </div>
             <div className="beInput">
               <label>Store Address-2 <span>*</span></label>
               <input
                 type="text"
                 name="registeredAddress1"
                 value={formData.registeredAddress1}
                 onChange={handleChange}
                 required
                 className="address-2"               />
               {displayError('registeredAddress1')}
             </div>
             <div className="two">
             <div className="beInput">
  <label> Select State <span>*</span></label>
  <select
    name="state"
    value={formData.state}
    onChange={handleChange}
    required
  >
    <option value="">Select State</option>
    <option value="MP">Madhya Pradesh (MP)</option>
    <option value="MH">Maharashtra (MH)</option>
  </select>
  {displayError('state')}
</div>

             <div className="beInput">
          <label>
            Select City <span>*</span>
          </label>
          <select
            name="city"
            value={formData.city}
            onChange={handleChange}
            required
          >
            <option value="">Select City</option>
            {cities.map((city, index) => (
              <option key={index} value={city}>
                {city}
              </option>
            ))}
          </select>
             </div>
             </div>

             <div className="two">
              <div className="beInput">
               <label style={{whiteSpace:"nowrap"}}>Pin Code <span>*</span></label>
               <input
                 type="text"
                 name="postalCode"
                 placeholder="Enter Pin Code"
                 value={formData.postalCode}
                 onChange={handleChange}
                 required
               />
               {displayError('postalCode')}
             </div>
            <div className="beInput">
             
               <label style={{whiteSpace:"nowrap"}}>Official Mail Address For Communication<span>*</span></label>
               <input
                 type="text"
                 name="OfficialMail"
                 placeholder="Official Mail Address For Communication"
                 value={formData.OfficialMail}
                 onChange={handleChange}
                 required
               />
               {displayError('OfficialMail')}
             </div>
             </div>
             <div className="next-page">
  <button className="submit-bt go-back" type="button" onClick={handlePrevious}>
    <FontAwesomeIcon icon={faArrowLeft} /> Go Back
  </button>
  <button className="submit-bt submit" type="button"onClick={handleNext}>
  Next <FontAwesomeIcon icon={faArrowRight} />
  </button>
</div>
           </>
            )}
            {currentSection === 3 && (
              <>
                <h3>SERVICE LISTINGS</h3>
                <div>
                <div className="Partcheckbox-group">
  <label className="Partcheckbox-label">
    <input
    type="checkbox"
    name="servicesOffered"
    value="Exterior Detailing"
    onChange={handleChange}
     className="Partcustom-checkbox"
  /> 
  Exterior Detailing  
   </label>
 <label className="partcheckbox-label">
  <input
    type="checkbox"
    name="servicesOffered"
    value="Interior Detailing"
    onChange={handleChange}
    className="Partcustom-checkbox"
  /> Interior Detailing</label>
 <label className="Partcheckbox-label">
  <input
    type="checkbox"
    name="servicesOffered"
    value="Full-Service Car Wash"
    onChange={handleChange}
    className="Partcustom-checkbox"
  /> Car Wash</label>
 <label className="Partcheckbox-label">
  <input
    type="checkbox"
    name="servicesOffered"
    value="Ceramic Coating"
    onChange={handleChange}
    className="Partcustom-checkbox"
  /> Ceramic Coating</label>
<label className="Partcheckbox-label">
  <input
    type="checkbox"
    name="servicesOffered"
    value="Paint Protection Film"
    onChange={handleChange}
    className="Partcustom-checkbox"
  /> Paint Protection Film</label>
   {formData.additionalServices && formData.additionalServices.map((service, index) => (
    <label key={index} className="Partcheckbox-label">
      <input
        type="checkbox"
        name="additionalService"
        value={service}
        onChange={handleChange}
        className="Partcustom-checkbox"
      /> 
      {service}
    </label>
  ))}

<label className="Partcheckbox-label">
  <input
    type="checkbox"
    name="servicesOffered"
    value="Other"
    onChange={handleChange}
    className="add-more-checkbox" // Ensure this class is applied
  />
  Add more
  <FontAwesomeIcon icon={faChevronDown} className="chevronADD-icon" />
</label>
 {formData.servicesOffered.includes('Other') && (

 <div className="add-service-container">
 <input 
   type="text"
   name="otherService"
   value={formData.otherService || ''}
   onChange={handleChange}
   placeholder="Add Service"
   required
   className="add-service-input" 
 />
<label className="Partcheckbox-label">
    <input type="checkbox"
    className="Partcustom-checkbox" onClick={handleAddMore} 
    checked={isChecked}/>
</label></div>
)}


  {displayError('servicesOffered')}
</div>

                </div>
                <div className="two">
                <div>
                <div className="beInput">
                  <label>Service Area Coverage: <span>*</span></label>
                  <select
                    name="serviceArea"
                    placeholder="select"
                    value={formData.serviceArea}
                    onChange={handleChange}
                    required
                  >
                    <option value="">Select...</option>
                    <option value="Local">Local</option>
                    <option value="Regional">Regional</option>
                    <option value="National">National</option>
                  </select>
                  {displayError('serviceArea')}
                  </div>
                </div>

                <div>
                <div className="beInput">
                  <label>No. of Service Employees <span>*</span></label>
                  <input
                    type="number"
                    name="numServiceBays"
                    value={formData.numServiceBays}
                    onChange={handleChange}
                    required
                  />
                   {displayError('numServiceBays')}
                   </div>
                </div>
                </div>
                <div className="two121">
               
                <div style={{display:'flex' , flexDirection:'column'}}>
                <h2>Operating Hours (Weekdays)<span>*</span> </h2>
                <div className="BeTime-flex">
       <form style={{display:'flex',gap:'10px'}}>
  <div >
    <select style={{width:'80px'}}
      id="hours"
      name="hours"
      value={formData.hours}
      onChange={handleChange}
      required
    >
      {Array.from({ length: 12 }, (_, i) => (
        <option key={i + 1} value={i + 1}>
          {i + 1}
        </option>
      ))}
    </select>
  </div>
  <div>
    <select  style={{ width: '80px' }}
      id="minutes"
      name="minutes"
      value={formData.minutes}
      onChange={handleChange}
      required
    >
      {Array.from({ length: 12 }, (_, i) => (
        <option key={i*5} value={i * 5 < 10 ? `0${i * 5}` : i * 5}>
          {i * 5 < 10 ? `0${i * 5}` : i * 5}
        </option>
      ))}
    </select>
  </div>

  <div>
    <select  style={{ width: '80px' }}
      id="ampm"
      name="ampm"
      value={formData.ampm}
      onChange={handleChange}
      required
    >
      <option value="AM">AM</option>
      <option value="PM">PM</option>
    </select>
  </div>
</form>
</div>
              
                </div>
               
                <div style={{display:'flex' , flexDirection:'column'}}>
                <h2>Operating Hours (Weekends)<span>*</span>  </h2>
                <form style={{display:'flex',gap:'10px'}}>
  <div >
    <select style={{width:'80px'}}
      id="hours"
      name="hours"
      value={formData.hours}
      onChange={handleChange}
      required
    >
      {Array.from({ length: 12 }, (_, i) => (
        <option key={i + 1} value={i + 1}>
          {i + 1}
        </option>
      ))}
    </select>
  </div>
  <div>
    <select  style={{ width: '80px' }}
      id="minutes"
      name="minutes"
      value={formData.minutes}
      onChange={handleChange}
      required
    >
      {Array.from({ length: 12 }, (_, i) => (
        <option key={i*5} value={i * 5 < 10 ? `0${i * 5}` : i * 5}>
          {i * 5 < 10 ? `0${i * 5}` : i * 5}
        </option>
      ))}
    </select>
  </div>

  <div>
    <select  style={{ width: '80px' }}
      id="ampm"
      name="ampm"
      value={formData.ampm}
      onChange={handleChange}
      required
    >
      <option value="AM">AM</option>
      <option value="PM">PM</option>
    </select>
  </div>
</form>
                </div>
                </div>

<div className="two">
<div style={{width:'72%'}}>
               <label>Store Closing Days</label>
               <select
                 name="storeclosed"
                 placeholder='Specify Store Off days'
                 value={formData.storeclosed}
                 onChange={handleChange}
                 required
               >
                 <option value="Sunday">Sunday</option>
                 <option value="Monday">Monday</option>
                 <option value="Tuesday">Tuesday</option>
                 <option value="Wednesday">Wednesday</option>
                 <option value="Thursday">Thursday</option>
                 <option value="Friday">Friday</option>
                 <option value="Saturday">Saturday</option>
               </select>
             </div>
             </div>
                <div className="next-page">
  <button className="submit-bt go-back" type="button" onClick={handlePrevious}>
    <FontAwesomeIcon icon={faArrowLeft} /> Go Back
  </button>
  <button className="submit-bt submit" type="button"onClick={handleNext}>
  Next <FontAwesomeIcon icon={faArrowRight} />
  </button>
</div>
              </>
            )}
            {currentSection === 4 && (
              <>

<div className="documentation-section">
      <h1>Documentation</h1>
      <div className="upload-list">
        {uploadCategories.map((category, index) => (
          <div key={index} className="upload-item">
            <div className="upload-header" onClick={() => toggleOpen(category)}>
              <label>{category}</label>
              <FontAwesomeIcon icon={isOpen[category] ? faMinus : faPlus} className="upload-toggle-icon" />
            </div>

            {isOpen[category] && (
              <div className="upload-section">
                <div className="upload-actions">
                  <label htmlFor={`file-${index}`} className="upload-button">
                    <FontAwesomeIcon icon={faCloudUploadAlt} /> Upload From Device
                  </label>
                  <input
                    type="file"
                    accept="image/jpeg"
                    id={`file-${index}`} 
                    onChange={(e) => handleFileChange(e, category)}
                    style={{ display: 'none' }}
                  />
                </div>

                {uploadedFiles[category] && (
                  <div className="uploaded-file-section">
                    <p className="uploaded-file-name">Uploaded: {uploadedFiles[category].name}</p>
                    <button 
                      className="delete-button"
                      onClick={() => handleDelete(category)}
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </button>
                  </div>
                )}
              </div>
            )}
          </div>
        ))}
      </div>
      <div className="tnc-section" >
  <div className="tnc-checkbox-wrapper">
    <p style={{color:'#FFBE00',  textDecoration: 'underline'}}>TnC and Policies</p>
  </div>
  <label htmlFor="tnc" style={{color:'#FFBE00'}}>
  <input type="checkbox" id="tnc"
      className="Partcustom-checkbox"
      />
    I have read & Accepted <a href="#" >Terms & Conditions</a><span style={{color:'#FF6B00'}}>*</span>
  </label>
</div>
    </div>
    <div className="next-page">
  <button className="submit-bt go-back" type="button" onClick={handlePrevious}>
    <FontAwesomeIcon icon={faArrowLeft} /> Go Back
  </button>
  <button className="submit-bt submit" type="submit" onClick={handleSubmit}>
    Submit <FontAwesomeIcon icon={faArrowRight} />
  </button>
</div>
              </>
            )}
          </form>
          {showSuccessMessage && <div className="success-message">Form submitted successfully!</div>}
        </div>
      )}
    </div>
  );
};

export default ResponsiveLayout;


