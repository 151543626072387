import React, { useState } from 'react';
import './CustomerDetails.css'; 
import './PaymentDetails.css';
import { faArrowRight, faArrowLeft, faCircleArrowRight, faCircleArrowUp,faChevronRight} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import logo from "../image/LOGO-03.png";
import { Link } from 'react-router-dom';
import StatusSidebar from './StatusSidebar';
import payupicon from '../image/payiconup.png';
import paurighticon from '../image/payiconright.png';


const PaymentDetails = () => {
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [upiId, setUpiId] = useState(''); // State for UPI ID
  const [cardNumber, setCardNumber] = useState(''); // State for Card Number

  const [activeStep, setActiveStep] = useState(4); 
  // Function to toggle dropdown
  const toggleDropdown = (dropdown) => {
    setActiveDropdown(activeDropdown === dropdown ? null : dropdown);
  };
  const handleFormSubmit = (e) => {
    e.preventDefault();
    // Perform form validation and submission logic here
    setActiveStep(4); // Move to the next step (Payment Confirmation)
  };
  return (
    <div className="customer-details-page">
     <div className="Status-sidebar">
  <StatusSidebar activeStep={activeStep}/>
</div>
      <div className="details-form">
        <h1>Payment Details</h1>
        <div className='payment-container'>
          <div className='banking'>
            
            {/* Net Banking Section */}
         <div className='Netbanking' onClick={() => toggleDropdown('netbanking')}>
  <div className="heading-with-icon">
    <h3>NET BANKING</h3>
    <img src={activeDropdown === 'netbanking' ? payupicon : paurighticon} />
  </div>
  {activeDropdown === 'netbanking' && (
    <div className="bank-dropdown-content">
      {/* Add Net Banking details here */}
      <p>Net Banking options...</p>
    </div>
  )}
</div>


            {/* Credit / Debit Section */}
            <div className='debit' onClick={() => toggleDropdown('debit')}>
            <div className="heading-with-icon">
              <h3>CREDIT / DEBIT</h3>
              <img src={activeDropdown === 'debit' ?  payupicon : paurighticon}  />
              </div>
              {activeDropdown === 'debit' && (
                <div
                 className="bank-dropdown-content"
                   onClick={(e) => e.stopPropagation()}
                   >
                  <div className="bank-label">
                    <label>Name On Card</label>
                    <input type="text" placeholder="Name as shown on the card" style={{width:'400px'}}/>
                  </div>
                  <div className="bank-label">
                    <label>Card Number</label>
                    <input type="text" placeholder="0000-0000-0000-0000" 
                     value={cardNumber} 
                     onChange={(e) => setCardNumber(e.target.value)}
                     />
                  </div>
                  <div className="Customer-row">
                    <div className="bank-label">
                      <label>Expiry Date</label>
                      <input type="text" placeholder="MM/YY" style={{width:'100px'}} />
                    </div>
                    <div className="bank-label">
                      <label>CVV</label>
                      <input type="text" placeholder="XXX" style={{width:'100px'}}/>
                    </div>
                  </div>
                </div>
              )}
            </div>

            {/* UPI Section */}
            <div className='UPI' onClick={() => toggleDropdown('upi')}>
            <div className="heading-with-icon">
            <h3>UPI</h3>
              <img src={activeDropdown === 'upi' ?  payupicon : paurighticon} />
</div>
              {activeDropdown === 'upi' && (
                <div 
                className="bank-dropdown-content"
                onClick={(e) => e.stopPropagation()}  >
<div className="bank-label">
                      <label>Enter Your UPI id</label>
                      <input type="text" placeholder="e.g.0000000000@ybl" 
                       value={upiId} 
                       onChange={(e) => setUpiId(e.target.value)}
                       />
                    </div>
                </div>
              )}
            </div>

            {/* Buttons */}
            <div className='go-back'>
            <div className='back-bank'>    <Link to="/CustomerDetails"> <button  ><FontAwesomeIcon icon={faArrowLeft} /> Go Back </button></Link></div>
            <div className='pay-bank'> <Link to="/BookingSummary"> <button > Pay Now <FontAwesomeIcon icon={faArrowRight} /></button></Link></div>
            </div>
          </div>

          {/* Payment Summary Box */}
          <div className='payment-box'>
            <div className="payment-logo">
              <a href="/"><img src={logo} alt="Logo" /></a>
              <a href="/" style={{ fontFamily: '"Chakra Petch", sans-serif' }}>AutoKaar</a>
            </div> 
            <div className='partner-garage'><h3>The detailing mafia</h3></div>
            <div className='payment-details'></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentDetails;



