import React, { useState } from 'react';
import "./loginform.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faLock, faEye, faEyeSlash ,faPhone ,faEnvelope } from '@fortawesome/free-solid-svg-icons'; 
import exterior3 from '../image/inside service/exterior3.jpg';
import { Link } from 'react-router-dom';


const Signup = () => {
    const [username, setUsername] = useState('');
    const [firstname, setFirstname] = useState('');
    const [lastname, setLastname] = useState('');
    const [number, setNumber] = useState('');
    const [password, setPassword] = useState('');
    const [confirmpassword, setConfirmPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [emailError, setEmailError] = useState('');
    const [numberError, setNumberError] = useState('');

    const handleSubmit = (event) => {
        event.preventDefault();
        
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(username)) {
            setEmailError('Invalid email address');
            return;
        } else {
            setEmailError('');
        }

        if (number.length !== 10) {
            setNumberError('Mobile number must be 10 digits');
            return;
        } else {
            setNumberError('');
        }

        if (username === 'admin' && password === 'password') {
            alert('Sign Up successful!');
        }
    };

    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const toggleShowConfirmPassword = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

    const handleNumberChange = (e) => {
        const value = e.target.value;
        if (value.length <= 10) {
            setNumber(value);
        }
    };

    return (
        <div className="login-container">
            <div className='flex-container'>
                <div className='lnsider-container1'>
                    <h2 style={{ fontFamily: '"Chakra Petch", sans-serif' }}>Sign Up</h2>
                    <form onSubmit={handleSubmit}>
                        <div className="login-form-group">
                            <div className='first-last'>
                                <div className="input-icon-container">
                                    <FontAwesomeIcon icon={faUser} className="input-icon" />
                                    <input
                                        type="text"
                                        id="firstname"
                                        placeholder="First Name"
                                        value={firstname}
                                        onChange={(e) => setFirstname(e.target.value)}
                                        required
                                    />
                                </div>
                                <div className="input-icon-container">
                                    <FontAwesomeIcon icon={faUser} className="input-icon" />
                                    <input
                                        type="text"
                                        id="lastname"
                                        placeholder="Last Name"
                                        value={lastname}
                                        onChange={(e) => setLastname(e.target.value)}
                                        required
                                    />
                                </div>
                            </div>
                            <div className='email-number'>
                            <div className="input-icon-container">
                                <FontAwesomeIcon icon={faEnvelope} className="input-icon" />
                                <input
                                    type="email"
                                    id="emailid"
                                    placeholder="Email ID "
                                    value={username}
                                    onChange={(e) => setUsername(e.target.value)}
                                    required
                                />
                                {emailError && <p className="error-text">{emailError}</p>}
                            </div>
                            <div className="input-icon-container">
                                <FontAwesomeIcon icon={faPhone} className="input-icon" />
                                <input
                                    type="number"
                                    id="number"
                                    placeholder="Mobile Number"
                                    value={number}
                                    onChange={handleNumberChange}
                                    required
                                />
                                {numberError && <p className="error-text">{numberError}</p>}
                            </div>
                            </div>
                        </div>
                        <div className="login-form-group">
                            <div className='pass-confirm'>
                                 <div className="input-icon-container">
                                <FontAwesomeIcon icon={faLock} className="input-icon" />
                                <input
                                    type={showPassword ? 'text' : 'password'} 
                                    id="password"
                                    placeholder="Password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    required
                                />
                                <FontAwesomeIcon
                                    icon={showPassword ? faEyeSlash : faEye}
                                    className="eye-icon"
                                    onClick={toggleShowPassword}
                                />
                            </div>
                            <div className="input-icon-container">
                                <FontAwesomeIcon icon={faLock} className="input-icon" />
                                <input
                                    type={showConfirmPassword ? 'text' : 'password'} 
                                    id="confirmpassword"
                                    placeholder="Confirm Password"
                                    value={confirmpassword}
                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                    required
                                />
                                <FontAwesomeIcon
                                    icon={showConfirmPassword ? faEyeSlash : faEye}
                                    className="eye-icon"
                                    onClick={toggleShowConfirmPassword}
                                />
                            </div>
                            </div>
                            <div className='text-10'>
                                <input type='checkbox' id='rememberMe' />
                                <span style={{ fontFamily: '"Chakra Petch", sans-serif' }}>Remember</span>
                            </div>
                        </div>
                        <button type="submit"className='login-sign'>Sign Up</button>
                    </form>
                    <p className='have-account'> Already have an account? <Link to="/login"> Login</Link></p>
                </div>
                <div className='lnsider-container2'>
                    <div className='for-image-container'>
                        <img src={exterior3} alt="Car" />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Signup;
