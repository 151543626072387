import React, { useState, useContext } from 'react';
import './Washing.css'; 
import exteriorwash from '../image/inside service/exterior wash.webp';
import interiorcleaning from '../image/inside service/interior cleaning.webp';
import ServiceSidebar from './ServiceSidebar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { ServiceContext } from './ServiceContext'; // Import the context
import { Link, useNavigate} from 'react-router-dom';

const Washing = () => {
  const [isAdded1, setIsAdded1] = useState(false);
  const { addService, removeService } = useContext(ServiceContext); 
  const navigate = useNavigate(); 

  const handleAddToCart1 = () => {
    if (!isAdded1) {
      setIsAdded1(true);
      addService(); 
    }
  };

  const handleCancel1 = () => {
    if (isAdded1) {
      setIsAdded1(false);
      removeService();
    }
  };

  return (
    <>
      <div className='washingpage-container' style={{display:'flex'}}>
        <ServiceSidebar />
        <div className="main-contant">
          <div className="services-contant">     
            <div className="services-contact-image">
              <img src={exteriorwash} alt="Car" />
            </div>
            <div className="services-contact-form">
              <h2 style={{ fontFamily: '"Chakra Petch", sans-serif' }}>Exterior Wash</h2>
              <p>Content to be provided</p>
            </div>
          </div>
          
          <div className="midd-contant">
            <div className="midd-contact-form">
              <h2 style={{ fontFamily: '"Chakra Petch", sans-serif' }}>Interior Cleaning</h2>
              <p>Content to be provided</p>
            </div>
            <div className="midd-contact-image">
              <img src={interiorcleaning} alt="Car" />
              <div className='add-cart'>
                {isAdded1 && (
                  <button className='cancel' onClick={handleCancel1}>
                    <FontAwesomeIcon icon={faXmark} />
                  </button> 
                )}
                <button className={`added ${isAdded1 ? 'added-active' : ''}`} 
                  onClick={handleAddToCart1}>
                  {isAdded1 ? "Service Added" : "Add To Cart"}
                </button>
              </div>
            </div>
          </div>
         <Link to="/ServiceListing" ><div className='service-partnerarrow'> 
            Proceed to Select Service Partner <FontAwesomeIcon icon={faArrowRight} />
          </div> </Link>
        </div>
      </div>
    </>
  );
};

export default Washing;
