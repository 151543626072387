import React from "react";
import "./ExteriortreatmentTable.css";

const PPFTable = () => {
  // Treatment details array
  const ppf = [
    {
      title: "0-11 Months",
      details: ["Yes"],
    },
    {
      title: "2-2yr 11 Months",
      details: ["Yes"],
    },
    {
      title: "3-3yr 11 Months",
      details: ["No"],
    },
    {
      title: "4-4yr 11 Months",
      details: ["No"],
    },
    {
      title: "5-5yr 11 Months",
      details: ["No"],
    },
    {
      title: "6yr+",
      details: ["No"],
    },
  ];

  // Only show the first treatment
  const filteredDetails = [ppf[0]]; // Only the first treatment

  return (
    <div className="treatment-table-container">
      <div className="treatment-details">
        <div className="treatment-detail-first">
          <h5>Paint Protection Film</h5>
          <p>Paint Protection Film (PPF)</p>
        </div>

        <div className="treatment-detail">
          <h5>Recommended</h5>
          <p>
            Recommended on new cars with fresh paint only unless scratched.
            Available in multiple warranty options like 3 Yrs, 5 Yrs, 7 Yrs, 8
            Yrs, 10 Yrs, 12 Yrs, depending upon the brand you select. Usually
            available in 3 qualities: PVC, TPH, and TPU (latest and highest
            quality available). No warranty in case of repainted panels.
          </p>
        </div>

        <div className="treatment-detail">
          <h5>Process</h5>
          <p>
            Starts with washing and then claying, swirl marks removal, and
            preparation of painted surface. Then removal of parts like door
            handles, monogram, and all other accessories which are applied over
            painted surface. PPF is applied over the painted surface. This
            process takes around 48-72 hours depending upon the weather
            condition to dry out. Then tuck-in is done to keep PPF intact with
            the painted surface for long, and your new car with an even more new
            look is ready to deliver.
          </p>
        </div>

        {/* Filtered treatment details will be displayed */}
        {filteredDetails.map((detail, index) => (
          <div key={index} className="treatment-detail">
            <h5>{detail.title}</h5>
            {detail.details.map((item, i) => (
              <p key={i}>{item}</p>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default PPFTable;