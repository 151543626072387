import React, { useState ,useContext} from 'react';
import './Washing.css'; 
import headlamp from '../image/inside service/headlamp.avif';
import ServiceSidebar from './ServiceSidebar';
import {faXmark, faArrowRight} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { ServiceContext } from './ServiceContext'; // Import the context

const Headlamp = ({ onStepComplete }) => {
    const [isAdded1, setIsAdded1] = useState(false);
    const { addService, removeService } = useContext(ServiceContext); // Use the context

    const handleAddToCart1 = () => {
      if (!isAdded1) {
          setIsAdded1(true);
          addService(); // Increment global service count
      }
  };

  const handleCancel1 = () => {
      if (isAdded1) {
          setIsAdded1(false);
          removeService(); // Decrement global service count
      }
  };

    return (
        <div className='washingpage-container' style={{display:'flex'}}>
        <ServiceSidebar />

               <div className="main-contant">
               <div className="midd-contant">
            <div className="services-contact-form">
            <h2 style={{ fontFamily: '"Chakra Petch", sans-serif' }}>Headlamp Restoration</h2>
            <p>Restores yellowed headlamps, saving up to 80% of the cost of replacing them.</p> </div>     
                 <div className="services-contact-image">
                     <img src={headlamp} alt="Car" />
                     <div className='add-wind-cart'>
                     <div className='add-cart'>
                {isAdded1 &&(
                <button className='cencel' onClick={handleCancel1}>
                    <FontAwesomeIcon icon={faXmark} />
                    </button> 
                )}
                              <button className={`added ${isAdded1 ? 'added-active' : ''}`} 
        onClick={handleAddToCart1}
    >
                {isAdded1 ? "Service Added" : "Add To Cart"}
              </button>            
            </div>                        </div>
                 </div>
        </div>
        <Link to="/ServiceListing" onClick={onStepComplete}><div className='service-partnerarrow'> 
            Proceed to Select Service Partner <FontAwesomeIcon icon={faArrowRight} />
          </div> </Link>
</div>
      </div>
    );
};

export default Headlamp;
