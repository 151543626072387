import React from 'react';
import './Ourservices.css';
import logo from '../image/Avatar.png';
import logo1 from '../image/Avatar2.png';
import logo2 from '../image/Avatar3.png';
import logo3 from '../image/Avatar4.png';
import logo4 from '../image/Avatar5.png';
import logo5 from '../image/Avatar 6.png';
import logo6 from '../image/Avatar7.png';
import logo7 from '../image/Avatar8.png';
import { Link } from 'react-router-dom';



const Services = () => {
  return (
    <div className="Our-container ">
      <div className="overlay">
        <div className=" h1text">
          <h1>Services We Offer</h1>{' '}
        </div>
        <div className="content">
          <div className="row">
          <Link to='/interiortreatment'> <div className="maincard1">
              <span className="icon">
                <img className="cardlogo" src={logo} alt="" />
              </span>

              <div className="card">
                <div className="card-body-bg">
                  <div className="card-body">
                  <div style={{paddingLeft:'51px'}}>
                    <h3 className="card-title">Interier Cleaning</h3>
                    <p>Cleaning of seats,carpets , dashboard etc....</p> </div>
                  </div>
                </div>
              </div>
            </div></Link> 
            <Link to='/exteriortreatment'><div className="maincard2">
              <span className="icon">
                <img className="cardlogo" src={logo1} alt="" />
              </span>
              <div className="card">
                <div className="card-body-bg">
                  <div className="card-body">
                    <div style={{paddingLeft:'51px'}}>
                    <h3 className="card-title">Exterier Treatments</h3>
                    <p>
                      Removing Imperfections like swirl marks and scretches.
                    </p>
                    </div>
                  </div>
                </div>
              </div>
            </div></Link> 
            <Link to='/airconditioner'><div className="maincard3">
              <span className="icon">
                <img className="cardlogo" src={logo2} alt="" />
              </span>
              <div className="card">
                <div className="card-body-bg">
                  <div className="card-body">
                  <div style={{paddingLeft:'51px'}}>
                    <h3 className="card-title">AC Cleaning</h3>
                    <p>
                      Removes Allergens & bacteria , ensuring fresh & clean
                      cooling{' '}
                    </p> </div>
                  </div>
                </div>
              </div>
            </div></Link>
           <Link to="/foamwash"> <div className="maincard4">
              <span className="icon">
                <img className="cardlogo" src={logo3} alt="" />
              </span>
              <div className="card">
                <div className="card-body-bg">
                  <div className="card-body">
                  <div style={{paddingLeft:'51px'}}>
                    <h3 className="card-title">Foam Wash</h3>
                    <p>
                      Foam wash to clean dirt , grims & debris for a clean
                      exterior surface
                    </p> </div>
                  </div>
                </div>
              </div>
            </div></Link>
           <Link to="/windshieldrestoration"> <div className="maincard5">
              <span className="icon">
                <img className="cardlogo" src={logo4} alt="" />
              </span>
              <div className="card">
                <div className="card-body-bg">
                  <div className="card-body">
                  <div style={{paddingLeft:'51px'}}>
                    <h3 className="card-title">WindShield Restoration</h3>
                    <p >
                      Restore miror crack & scratches , hence improving clarity
                      and overall visibility
                    </p></div>
                  </div>
                </div>
              </div>
            </div></Link>
           <Link to="/antirustprotection"> <div className="maincard6">
              <span className="icon">
                <img className="cardlogo" src={logo5} alt="" />
              </span>
              <div className="card">
                <div className="card-body-bg">
                  <div className="card-body">
                  <div style={{paddingLeft:'51px'}}>
                    <h3 className="card-title">Anti-Rust Coating</h3>
                    <p>Protecting underbody from rust</p></div>
                  </div>
                </div>
              </div>
            </div></Link>
           <Link to="/engineprotection"> <div className="maincard7">
              <span className="icon">
                <img className="cardlogo" src={logo6} alt="" />
              </span>
              <div className="card">
                <div className="card-body-bg">
                  <div className="card-body">
                  <div style={{paddingLeft:'51px'}}>
                    <h3 className="card-title">Engine Protection</h3>
                    <p>
                      Treatements protecting engine from overheating rat
                      bites...
                    </p></div>
                  </div>
                </div>
              </div>
            </div></Link>
           <Link to="/paintprotectionfilm"> <div className="maincard8">
              <span className="icon">
                <img className="cardlogo" src={logo7} alt="" />
              </span>
              <div className="card">
                <div className="card-body-bg">
                  <div className="card-body">
                  <div style={{paddingLeft:'51px'}}>
                    <h3 className="card-title">PPF</h3>
                    <p>
                      PPF shield your vehicles paint from scretches , chips and
                      UV damage.
                    </p></div>
                  </div>
                </div>
              </div>
            </div></Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;