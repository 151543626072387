import React, { useState ,useContext } from 'react';
import './Washing.css'; 
import wind  from '../image/inside service/windshield.webp';
import image from '../image/washcar.jpg';
import ServiceSidebar from './ServiceSidebar';
import {faXmark, faArrowRight} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ServiceContext } from './ServiceContext'; // Import the context
import { Link } from 'react-router-dom';

const Windshieldrestoration = () => {
    const [isAdded1, setIsAdded1] = useState(false);
    const { addService, removeService } = useContext(ServiceContext); // Use the context

    const handleAddToCart1 = () => {
      if (!isAdded1) {
          setIsAdded1(true);
          addService(); // Increment global service count
      }
  };

  const handleCancel1 = () => {
      if (isAdded1) {
          setIsAdded1(false);
          removeService(); // Decrement global service count
      }
  };

    return (
        <div className='washingpage-container' style={{display:'flex'}}>
        <ServiceSidebar />

        <div className="main-contant">    
                   <div className="midd-contant">
                <div className="services-contact-form">
                    <h2 style={{ fontFamily: '"Chakra Petch", sans-serif' }}>Windshield restoration</h2>
                    <p>Removes minor scratches, swirls, and imperfections from windshields, restoring clarity and improving safety, enhancing resale value.</p> 
                   
                    </div>     
                     <div className="services-contact-image">
                         <img src={wind } alt="Car" />
                         <div className='add-cart'>
                {isAdded1 &&(
                <button className='cencel' onClick={handleCancel1}>
                    <FontAwesomeIcon icon={faXmark} />
                    </button> 
                )}
                              <button className={`added ${isAdded1 ? 'added-active' : ''}`} 
        onClick={handleAddToCart1}
    >
                {isAdded1 ? "Service Added" : "Add To Cart"}
              </button>            
            </div>
                     </div>
            </div>
            <Link to="/ServiceListing" ><div className='service-partnerarrow'> 
            Proceed to Select Service Partner <FontAwesomeIcon icon={faArrowRight} />
          </div> </Link></div>
          </div>
        );
    };
    
    export default Windshieldrestoration;
    