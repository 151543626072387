import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faSquare, faCheckSquare } from "@fortawesome/free-solid-svg-icons";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import './ServiceListingSidebar.css';

const ServiceListingSidebar = () => {
  const [activeDropdowns, setActiveDropdowns] = useState({
    services: true,
    priceRange: true,
    distanceRange: true,
    ratingsAndReviews: true,
    serviceTime: true,
    dealsAndDiscounts: true,
  });

  const [selectedCheckboxes, setSelectedCheckboxes] = useState({
    ReasonableWashing: false,
    MultiBrandWorkshops: false,
    MidRangeDetailing: false,
    PremiumDetailing: false,
    InstantServiceProvider: false,
    Instant: false,
    Within2_3days: false,
    Flexible: false,
    NoPriceConstraint: false,
  });

  const [price, setPrice] = useState(100);
  const [distance, setDistance] = useState(0);

  const toggleDropdown = (dropdown) => {
    setActiveDropdowns((prev) => ({
      ...prev,
      [dropdown]: !prev[dropdown],
    }));
  };

  const handleCheckboxChange = (name) => {
    setSelectedCheckboxes((prev) => ({
      ...prev,
      [name]: !prev[name],
    }));
  };
  

  return (
    <div className="filter-container">
      <div className="filter-header" style={{ color: "#fff" }}>
        <h2>Filters</h2>
        <div className="clear-apply">
          <span className="clear-all">Clear all</span>
          <button className="apply-btn" style={{background:"black", color:"#fff", border:"1px solid #FFBE00"}}>
            <FontAwesomeIcon icon={faCheck} style={{ color: "#FFBE00" }} />{" "}
            Apply
          </button>
        </div>
      </div>

      {/* Service Provider Category */}
      <div className="filter-section">
        <div className="filter-category">
          <div onClick={() => toggleDropdown("services")}
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <h3
              style={{ fontFamily: '"Chakra Petch", sans-serif' }}
            >
              Service Provider Category
            </h3>
            {activeDropdowns.services ? <FaChevronUp /> : <FaChevronDown />}
          </div>
          {activeDropdowns.services && (
            <div
              className="checkbox-group"
              style={{
                color: "#fff",
                display: "flex",
                flexDirection: "column",
              }}
            >
              {[
                "ReasonableWashing",
                "MultiBrandWorkshops",
                "MidRangeDetailing",
                "PremiumDetailing",
                "InstantServiceProvider",
              ].map((category) => (
                <label key={category} className="checkbox-label">
                  <FontAwesomeIcon
                    icon={selectedCheckboxes[category] ? faCheck : faSquare}
                    onClick={() => handleCheckboxChange(category)}
                    className="custom-checkbox"
                    style={{
                      color: selectedCheckboxes[category]
                        ? "#FFBE00"
                        : "transparent",
                    }}
                  />
                  {category.replace(/([A-Z])/g, " $1")}
                </label>
              ))}
            </div>
          )}
        </div>

        <div className="filter-category">
          <div onClick={() => toggleDropdown("priceRange")}
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <h3
              style={{ fontFamily: '"Chakra Petch", sans-serif' }}
            >
              Price Range
            </h3>
            {activeDropdowns.priceRange ? <FaChevronUp /> : <FaChevronDown />}
          </div>
          {activeDropdowns.priceRange && (
            <div className="slider-group">
              <span style={{ color: "#FFBE00" }}>{price.toLocaleString()}</span>
              <input
  type="range"
  min="100"
  max="400000"
  value={price}
  onChange={(e) => {
    const value = e.target.value;
    setPrice(value);
    e.target.style.background = `linear-gradient(
      to right,
      #FFBE00 0%,
      #FFBE00 ${(value / 400000) * 100}%,
      #bd5c17 ${(value / 400000) * 100}%,
      #bd5c17 100%
    )`;
  }}
  style={{
    background: `linear-gradient(
      to right,
      #FFBE00 0%,
      #FFBE00 ${(price / 400000) * 100}%,
      #bd5c17 ${(price / 400000) * 100}%,
      #bd5c17 100%
    )`,
  }}
/>
              <div
                className="range-labels"
                style={{ color: "#fff", marginBottom: "10px" }}
              >
                <span style={{ color: "#FF6B00" }}>₹100</span>
                <span style={{ color: "#FF6B00" }}>₹4,00,000</span>
              </div>

              <label className="checkbox-label">
                <FontAwesomeIcon
                  icon={
                    selectedCheckboxes.NoPriceConstraint ? faCheck : faSquare
                  }
                  className="custom-checkbox"
                  style={{
                    color: selectedCheckboxes.NoPriceConstraint
                      ? "#FFBE00"
                      : "transparent",
                  }}
                  onClick={() => handleCheckboxChange("NoPriceConstraint")}
                />
                No Price Constraint
              </label>
            </div>
          )}
        </div>

        {/* Distance Range */}
        <div className="filter-category">
          <div
           onClick={() => toggleDropdown("distanceRange")}
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <h3
              style={{ fontFamily: '"Chakra Petch", sans-serif' }}
            >
              Distance Range
            </h3>
            {activeDropdowns.distanceRange ? (
              <FaChevronUp />
            ) : (
              <FaChevronDown />
            )}
          </div>
          {activeDropdowns.distanceRange && (
            <div className="slider-group">
              <span style={{ color: "#FFBE00" }}>
                {distance.toLocaleString()} km
              </span>
              <input
  type="range"
  min="0"
  max="60"
  value={distance}
  onChange={(e) => {
    const value = e.target.value;
    setDistance(value);
    e.target.style.background = `linear-gradient(
      to right,
      #FFBE00 0%,
      #FFBE00 ${(value / 60) * 100}%,
      #bd5c17 ${(value / 60) * 100}%,
      #bd5c17 100%
    )`;
  }}
  style={{
    background: `linear-gradient(
      to right,
      #FFBE00 0%,
      #FFBE00 ${(distance / 60) * 100}%,
      #bd5c17 ${(distance / 60) * 100}%,
      #bd5c17 100%
    )`,
  }}
/>
              <div className="range-labels">
                <span style={{ color: "#FF6B00" }}>0 km</span>
                <span style={{ color: "#FF6B00" }}>60 km</span>
              </div>
            </div>
          )}
        </div>

        {/* Ratings and Reviews */}
        {/* <div className="filter-category">
          <div onClick={() => toggleDropdown("ratingsAndReviews")}
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <h3
              style={{ fontFamily: '"Chakra Petch", sans-serif' }}
            >
              Ratings and Reviews
            </h3>
            {activeDropdowns.ratingsAndReviews ? (
              <FaChevronUp />
            ) : (
              <FaChevronDown />
            )}
          </div>
          {activeDropdowns.ratingsAndReviews && (
            <div
              className="checkbox-group"
              style={{
                color: "#fff",
                display: "flex",
                flexDirection: "column",
              }}
            >
              {["5 Star", "4 Star", "3 Star", "2 Star", "1 Star"].map(
                (rating) => (
                  <label key={rating} className="checkbox-label">
                    <FontAwesomeIcon
                      icon={selectedCheckboxes[rating] ? faCheck : faSquare}
                      onClick={() => handleCheckboxChange(rating)}
                      className="custom-checkbox"
                      style={{
                        color: selectedCheckboxes[rating]
                          ? "#FFBE00"
                          : "transparent",
                      }}
                    />{" "}
                    {rating.replace(/([A-Z])/g, " $1")}
                  </label>
                )
              )}
            </div>
          )}
        </div> */}

        {/* Service Time */}
        <div className="filter-category">
          <div onClick={() => toggleDropdown("serviceTime")}
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <h3
              style={{ fontFamily: '"Chakra Petch", sans-serif' }}
              
            >
              Service Time
            </h3>
            {activeDropdowns.serviceTime ? <FaChevronUp /> : <FaChevronDown /> }
            
          </div>
          {activeDropdowns.serviceTime && (
            <div
              className="checkbox-group"
              style={{
                color: "#fff",
                display: "flex",
                flexDirection: "column",
              }}
            >
              {["Instant", "Within 2-3 days", "Flexible"].map((time) => (
                <label key={time} className="checkbox-label">
                  <FontAwesomeIcon
                    icon={selectedCheckboxes[time] ? faCheck : faSquare}
                    onClick={() => handleCheckboxChange(time)}
                    className="custom-checkbox"
                    style={{
                      color: selectedCheckboxes[time]
                        ? "#FFBE00"
                        : "transparent",
                    }}
                  />{" "}
                  {time.replace(/([A-Z])/g, " $1")}
                </label>
              ))}
            </div>
          )}
        </div>

        {/* Deals and Discounts */}
        {/* <div className="filter-category">
          <div onClick={() => toggleDropdown("dealsAndDiscounts")}
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <h3
              style={{ fontFamily: '"Chakra Petch", sans-serif' }}
            >
              Deals and Discounts
            </h3>
            {activeDropdowns.dealsAndDiscounts ? (
              <FaChevronUp />
            ) : (
              <FaChevronDown />
            )}
          </div>
          {activeDropdowns.dealsAndDiscounts && (
            <div
              className="checkbox-group"
              style={{
                color: "#fff",
                display: "flex",
                flexDirection: "column",
              }}
            >
              {["50% and Above", "30%-50", "up to 20%"].map((discount) => (
                <label key={discount} className="checkbox-label">
                  <FontAwesomeIcon
                    icon={selectedCheckboxes[discount] ? faCheck : faSquare}
                    onClick={() => handleCheckboxChange(discount)}
                    className="custom-checkbox"
                    style={{
                      color: selectedCheckboxes[discount]
                        ? "#FFBE00"
                        : "transparent",
                    }}
                  />{" "}
                  {discount.replace(/([A-Z])/g, " $1")}
                </label>
              ))}
            </div>
          )}
        </div> */}
      </div>
    </div>
  );
};

export default ServiceListingSidebar;
