import React, { useState, useContext } from "react";  // Added useContext
import { Link, useLocation } from 'react-router-dom';
import { FaChevronLeft, FaChevronUp } from "react-icons/fa";
import './ServiceSidebar.css';
import { ServiceContext } from './ServiceContext'; // Assuming ServiceContext is defined in ServiceContext.js

const ServiceSidebar = () => {
    const { serviceCount } = useContext(ServiceContext); // Now using useContext correctly
    const [activeService, setActiveService] = useState(null);
    const location = useLocation(); 

    React.useEffect(() => {
        setActiveService(location.pathname);
    }, [location]);

    return (
        <div className='SidebarServices'>
            <div className="SidebarServices-h4" style={{ color: '#fff' }}>
            <h4>
  Services Selected <span className="service-count">{serviceCount}</span>
</h4>
            </div>
            <ul className="service-menu">
                {services.map((service) => (
                    <li
                        className={`service-item-web ${activeService === service.link ? 'active' : ''}`}
                        key={service.link}
                    >
                        <Link
                            to={service.link}
                            onClick={() => setActiveService(service.link)} 
                        >
                            {service.name}
                            {activeService === service.link ? 
                                (<FaChevronLeft className="active-icon" />) : 
                                (<FaChevronUp className="inactive-icon" />)
                            }
                        </Link>
                    </li>
                ))}
            </ul>
        </div>
    );
};

const services = [
    { name: 'Wash', link: '/foamwash' },
    { name: 'Paint Protection Film', link: '/paintprotectionfilm' },
    { name: 'Ceramic Coating', link: '/ceramiccoating' },
    { name: 'Interior Treatments', link: '/interiortreatment' },
    { name: 'Exterior Treatments', link: '/exteriortreatment' },
    { name: 'AC Care', link: '/airconditioner' },
    { name: 'Antirust Coatings', link: '/antirustprotection' },
    { name: 'Engine Protection', link: '/engineprotection' },
    { name: 'Windshield Restoration', link: '/windshieldrestoration' },
    { name: 'Headlamp Restoration', link: '/headlamprestoration' },
];

export default ServiceSidebar;
