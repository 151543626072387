import React, { useState , useContext } from 'react';
import './Washing.css'; 
import exterior1 from '../image/inside service/ppf2.jpg';
import exterior2 from '../image/inside service/exterior2.jpg';
import exterior3 from '../image/inside service/exterior3.jpg';
import exterior4 from '../image/inside service/exterior4.jpg';
import image from '../image/washcar.jpg';
import ServiceSidebar from './ServiceSidebar';
import {faXmark, faArrowRight} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ServiceContext } from './ServiceContext'; // Import the context
import { Link } from 'react-router-dom';



const Exteriortreatment= () => {
    const [addedService, setAddedService] = useState(null); // Track which service is added
    const { addService, removeService } = useContext(ServiceContext); // Use the context

    const handleAddToCart = (serviceIndex) => {
        if (addedService !== serviceIndex) {
            if (addedService !== null) {
                removeService(); // Decrement global service count
            }
            setAddedService(serviceIndex);
            addService(); // Increment global service count
        }
    };

    const handleCancel = () => {
        if (addedService !== null) {
            removeService(); // Decrement global service count
            setAddedService(null); // Reset added service
        }
    };

    return (
        <div className='washingpage-container' style={{display:'flex'}}>
        <ServiceSidebar />

               <div className="main-contant">
    <div className="services-contant">
    <div className="services-contact-image">
                     <img src={exterior1} alt="Car" />
                 </div>
            <div className="services-contact-form">
                <h2 style={{ fontFamily: '"Chakra Petch", sans-serif' }}>Exterior Care</h2>
                <p>Improving the vehicle's exterior appearance through buffing and polishing painted surfaces.</p>  
                <h3 style={{ fontFamily: '"Chakra Petch", sans-serif' }}>Benefits:</h3>
   <li>	Removes dirt, grime, and contaminants.</li>
   <li>	Protects and preserves the surface.</li>
   <li>	Restores paint shine and luster.</li>
   <li>	Increases resale value.</li>
   <li>	Eliminates swirl marks and scratches.</li></div> 

</div>
    
           <div className="midd-contant"  >
            <div className="midd-contact-form">
                <h2 style={{ fontFamily: '"Chakra Petch", sans-serif' }}>Paint Correction</h2>
                <p>Content to be provided</p>
               </div>
               <div className="midd-contact-image">
                <img src={exterior2} alt="Car" />
                <div className='add-cart'>
                            {addedService === 1 && (
                                <button className='cencel' onClick={handleCancel}>
                                    <FontAwesomeIcon icon={faXmark} />
                                </button>
                            )}
                            <button className={`added ${addedService === 1 ? 'added-active' : ''}`} 
                                onClick={() => handleAddToCart(1)} 
                                disabled={addedService !== null && addedService !== 1} // Disable if another service is added
                            >
                                {addedService === 1 ? "Service Added" : (addedService !== null ? "Disabled" : "Add To Cart")}
                            </button>            
                        </div>
            </div></div>

               <div className="midd-contant">
            <div className="midd-contact-form">
                <h2 style={{ fontFamily: '"Chakra Petch", sans-serif' }}>Exterior Enrichment</h2>
                <p>Content to be provided</p>
 </div>      
                 <div className="midd-contact-image">
                     <img src={exterior3} alt="Car" />
                     <div className='add-cart'>
                            {addedService === 2 && (
                                <button className='cencel' onClick={handleCancel}>
                                    <FontAwesomeIcon icon={faXmark} />
                                </button>
                            )}
                            <button className={`added ${addedService === 2 ? 'added-active' : ''}`} 
                                onClick={() => handleAddToCart(2)} 
                                disabled={addedService !== null && addedService !== 2} // Disable if another service is added
                            >
                                {addedService === 2 ? "Service Added" : (addedService !== null ? "Disabled" : "Add To Cart")}
                            </button>                 
            </div>     
                 </div></div>
     
               <div className="midd-contant">
            <div className="midd-contact-form">
                <h2 style={{ fontFamily: '"Chakra Petch", sans-serif' }}>Surface Correction</h2>
                <p>Content to be provided</p>
               </div>
               <div className="midd-contact-image">
                <img src={exterior4} alt="Car" />
                <div className='add-cart'>
                            {addedService === 3 && (
                                <button className='cencel' onClick={handleCancel}>
                                    <FontAwesomeIcon icon={faXmark} />
                                </button>
                            )}
                            <button className={`added ${addedService === 3 ? 'added-active' : ''}`} 
                                onClick={() => handleAddToCart(3)} 
                                disabled={addedService !== null && addedService !== 3} // Disable if another service is added
                            >
                                {addedService === 3 ? "Service Added" : (addedService !== null ? "Disabled" : "Add To Cart")}
                            </button>                        
            </div>
            </div>
        </div>
       <Link to="/ServiceListing"> <div className='service-partnerarrow'> Proceed to Select Service Partner <FontAwesomeIcon icon={faArrowRight} /></div></Link>
        </div>
        </div>
    );
};

export default Exteriortreatment;
