import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import './PartnerSidebar.css';
import statuscheck from '../image/satuschack.png';      // Inactive icon
import statuscheckfill from '../image/satuschackfill.png'; // Active icon

const PartnerSidebar = ({ activeStep }) => {
  // Determine the icon color class based on the active step
  const getIconSrc = (step) => (step <= activeStep ? statuscheckfill : statuscheck);

  return (
    <div className="Status-sidebar">
      <h3>Partner with Us</h3>
      <ul>
        <li style={{ color: activeStep >= 1 ? '#FFBE00' : '#FFFFFF', display: 'flex', alignItems: 'center',gap:'16px'}}>
          <img src={getIconSrc(1)} alt="status icon" /> Contact Information
        </li>
        <li style={{ color: activeStep >= 2 ? '#FFBE00' : '#FFFFFF', display: 'flex', alignItems: 'center',gap:'16px' }}>
          <img src={getIconSrc(2)} alt="status icon" /> Company Details
        </li>
        <li style={{ color: activeStep >= 3 ? '#FFBE00' : '#FFFFFF', display: 'flex', alignItems: 'center',gap:'16px' }}>
          <img src={getIconSrc(3)} alt="status icon" /> Service Listings
        </li>
        <li style={{ color: activeStep >= 4 ? '#FFBE00' : '#FFFFFF', display: 'flex', alignItems: 'center',gap:'16px' }}>
          <img src={getIconSrc(4)} alt="status icon" /> Documentation
        </li>
        <li style={{ color: activeStep >= 5 ? '#FFBE00' : '#FFFFFF', display: 'flex', alignItems: 'center',gap:'16px' }}>
          <img src={getIconSrc(5)} alt="status icon" />  Submit
        </li>
      </ul>

    </div>
  );
};

export default PartnerSidebar;
