import React, { createContext, useState } from 'react';

// Create context
export const ServiceContext = createContext();

// Context provider component
export const ServiceProvider = ({ children }) => {
  const [serviceCount, setServiceCount] = useState(0);
  
  const addService = () => setServiceCount(serviceCount + 1);
  const removeService = () => setServiceCount(serviceCount - 1);

  return (
    <ServiceContext.Provider value={{ serviceCount, addService, removeService }}>
      {children}
    </ServiceContext.Provider>
  );
};
