import React, { useState ,useContext } from 'react';
import './Washing.css'; 
import Acvent from '../image/inside service/ac vent.webp';
import Accoli from '../image/inside service/ac coli.jpg';
import image from '../image/washcar.jpg';
import ServiceSidebar from './ServiceSidebar';
import {faXmark, faArrowRight} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { ServiceContext } from './ServiceContext'; // Import the context

const Airconditioner= () => {
    const [isAdded1, setIsAdded1] = useState(false);
    const { addService, removeService } = useContext(ServiceContext); // Use the context

    const handleAddToCart1 = () => {
        if (!isAdded1) {
            setIsAdded1(true);
            addService(); // Increment global service count
        }
    };
  
    const handleCancel1 = () => {
        if (isAdded1) {
            setIsAdded1(false);
            removeService(); // Decrement global service count
        }
    };
    return (
        <div className='washingpage-container' style={{display:'flex'}}>
        <ServiceSidebar />

               <div className="main-contant">
               <div className="services-contant">
            <div className="services-contact-form">
                <h2 style={{ fontFamily: '"Chakra Petch", sans-serif' }}>AC Care
</h2>
                <p>Includes cleaning AC ducts and coils to reduce allergens and bacteria, improve air quality, eliminate odors, and enhance AC performance, which can affect fuel efficiency.</p> 
                

                 </div>     
                 <div className="services-contact-image">
                     <img src={Acvent} alt="Car" />
                 </div></div>

               <div className="midd-contant">
            <div className="midd-contact-form">
                <h2 style={{ fontFamily: '"Chakra Petch", sans-serif' }}>AC Evaporator Coil Cleaning</h2>
                <p>Content to be provided</p>
               </div>
               <div className="midd-contact-image">
                <img src={Accoli} alt="Car" />
                <div className='add-cart'>
                {isAdded1 &&(
                <button className='cencel' onClick={handleCancel1}>
                    <FontAwesomeIcon icon={faXmark} />
                    </button> 
                )}
                              <button className={`added ${isAdded1 ? 'added-active' : ''}`} 
        onClick={handleAddToCart1}
    >
                {isAdded1 ? "Service Added" : "Add To Cart"}
              </button>            
            </div>
            </div>
        </div>
        <Link to="/ServiceListing" ><div className='service-partnerarrow'> 
            Proceed to Select Service Partner <FontAwesomeIcon icon={faArrowRight} />
          </div> </Link>        </div>
      </div>
    );
};

export default Airconditioner;
