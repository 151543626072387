
import React, { useState, useEffect } from "react";
import "./ServiceListing.css";
import ServiceListingSidebar from "./ServiceListingSidebar";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { Link } from 'react-router-dom';
import { SlCalender } from "react-icons/sl";
import { ImPencil } from "react-icons/im";
import { FaCar } from "react-icons/fa";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const servicesData = [
  {
    id: 1,
    name: "Carz Cafe",
    description:
      "Improving the vehicle’s exterior appearance through buffing and polishing painted surfaces.",
    descriptionTwo:
      "Improving the vehicle’s exterior appearance through buffing and polishing painted surfaces.",
    rating: 4.8,
    location: "Kolar",
    distance: "2–5kms",
    price: "5000",
    logo: "/Images/carz_cafe.png",
    starIcon: "/Images/star-icon.png",
    locationIcon: "/Images/location-Vector.png",
    distanceIcon: "/Images/distance-Vector.png",
    rightArrow: "/Images/right-arrow.png",
    topArrow: "/Images/top-arrow.png",
    priceBreakUpOne: "1. UV Protection",
    priceBreakUpTwo: "2. Interior Enrichment",
    priceBreakUpThree: "3. Surface Correction",
    priceBreakUpFour: "4. Paint Correction",
    priceListOne: "1500",
    priceListTwo: "2500",
    priceListThree: "3000",
    priceListFour: "2000",

  },
  {
    id: 2,
    name: "The Detail Mafia",
    description:
      "Improving the vehicle’s exterior appearance through buffing and polishing painted surfaces.",
    descriptionTwo:
      "Improving the vehicle’s exterior appearance through buffing and polishing painted surfaces.",
    rating: 4.8,
    location: "Kolar",
    distance: "2–5kms",
    price: "9000",
    logo: "/Images/detail_mafia.png",
    starIcon: "/Images/star-icon.png",
    locationIcon: "/Images/location-Vector.png",
    distanceIcon: "/Images/distance-Vector.png",
    rightArrow: "/Images/right-arrow.png",
    topArrow: "/Images/top-arrow.png",
    priceBreakUpOne: "UV Protection",
    priceBreakUpTwo: "Interior Enrichment",
    priceBreakUpThree: "Surface Correction",
    priceBreakUpFour: "Paint Correction",
  },
  {
    id: 3,
    name: "Carz Spa",
    description:
      "Improving the vehicle’s exterior appearance through buffing and polishing painted surfaces.",
    descriptionTwo:
      "Improving the vehicle’s exterior appearance through buffing and polishing painted surfaces.",
    rating: 4.8,
    location: "Kolar",
    distance: "2–5kms",
    price: "9000",
    logo: "/Images/carz-spa.png",
    starIcon: "/Images/star-icon.png",
    locationIcon: "/Images/location-Vector.png",
    distanceIcon: "/Images/distance-Vector.png",
    rightArrow: "/Images/right-arrow.png",
    topArrow: "/Images/top-arrow.png",
    priceBreakUpOne: "UV Protection",
    priceBreakUpTwo: "Interior Enrichment",
    priceBreakUpThree: "Surface Correction",
    priceBreakUpFour: "Paint Correction",
  },
  {
    id: 4,
    name: "Detailing Devils",
    description:
      "Improving the vehicle’s exterior appearance through buffing and polishing painted surfaces.",
    descriptionTwo:
      "Improving the vehicle’s exterior appearance through buffing and polishing painted surfaces.",
    rating: 4.8,
    location: "Kolar",
    distance: "2–5kms",
    price: "9000",
    logo: "/Images/detailing_devils.png",
    starIcon: "/Images/star-icon.png",
    locationIcon: "/Images/location-Vector.png",
    distanceIcon: "/Images/distance-Vector.png",
    rightArrow: "/Images/right-arrow.png",
    topArrow: "/Images/top-arrow.png",
    priceBreakUpOne: "UV Protection",
    priceBreakUpTwo: "Interior Enrichment",
    priceBreakUpThree: "Surface Correction",
    priceBreakUpFour: "Paint Correction",
  },
  {
    id: 5,
    name: "Moto Craftz",
    description:
      "Improving the vehicle’s exterior appearance through buffing and polishing painted surfaces.",
    descriptionTwo:
      "Improving the vehicle’s exterior appearance through buffing and polishing painted surfaces.",
    rating: 4.8,
    location: "Kolar",
    distance: "2–5kms",
    price: "9000",
    logo: "/Images/moto_craftz.png",
    starIcon: "/Images/star-icon.png",
    locationIcon: "/Images/location-Vector.png",
    distanceIcon: "/Images/distance-Vector.png",
    rightArrow: "/Images/right-arrow.png",
    topArrow: "/Images/top-arrow.png",
    priceBreakUpOne: "UV Protection",
    priceBreakUpTwo: "Interior Enrichment",
    priceBreakUpThree: "Surface Correction",
    priceBreakUpFour: "Paint Correction",
  },
  {
    id: 6,
    name: "Spa My Car",
    description:
      "Improving the vehicle’s exterior appearance through buffing and polishing painted surfaces.",
    descriptionTwo:
      "Improving the vehicle’s exterior appearance through buffing and polishing painted surfaces.",
    rating: 4.8,
    location: "Kolar",
    distance: "2–5kms",
    price: "9000",
    logo: "/Images/spa_my_car.png",
    starIcon: "/Images/star-icon.png",
    locationIcon: "/Images/location-Vector.png",
    distanceIcon: "/Images/distance-Vector.png",
    rightArrow: "/Images/right-arrow.png",
    topArrow: "/Images/top-arrow.png",
    priceBreakUpOne: "UV Protection",
    priceBreakUpTwo: "Interior Enrichment",
    priceBreakUpThree: "Surface Correction",
    priceBreakUpFour: "Paint Correction",
  },
];

const ServiceListing = ({ onStepComplete = () => {} }) => {
  const [services, setServices] = useState(servicesData);
  const [isPopupOpen, setIsPopupOpen] = useState(true);
  const [showContent, setShowContent] = useState(false);

  const handleSelect = (id) => {
    setServices((prevServices) =>
      prevServices.map((service) =>
        service.id === id
          ? { ...service, selected: true } // Mark selected service
          : { ...service, selected: false } // Deselect others
      )
    );
    onStepComplete(); // Call the function when a service is selected
  };


  useEffect(() => {
    setIsPopupOpen(false);
  }, []);

// Close the popup when the user clicks "OK"
const handleClosePopup = () => {
  setIsPopupOpen(false);
};

useEffect(() => {
  setIsPopupOpen(true);
}, []);

// const showDetailClickHandler = () => {
//   setshowContent(!showContent);
// };

const brandName = [
  { brand_id: 1, brand_name: "MARUTI SUZUKI" },
  { brand_id: 2, brand_name: "TATA" },
  { brand_id: 3, brand_name: "Toyota" },
  { brand_id: 4, brand_name: "Mahindra" },
  { brand_id: 5, brand_name: "Hyundai" },
  { brand_id: 6, brand_name: "Kia" },
  { brand_id: 7, brand_name: "Skoda" },
  { brand_id: 8, brand_name: "MG" },
  { brand_id: 9, brand_name: "VW" },
  { brand_id: 10, brand_name: "Renault" },
  { brand_id: 11, brand_name: "Honda" },
  { brand_id: 12, brand_name: "Jeep" },
  { brand_id: 13, brand_name: "Nissan" },
  { brand_id: 14, brand_name: "Chevrolet" },
  { brand_id: 15, brand_name: "Fiat" },
  { brand_id: 16, brand_name: "Audi" },
  { brand_id: 17, brand_name: "BMW" },
  { brand_id: 18, brand_name: "Mercedes" },
  { brand_id: 19, brand_name: "Land Rover" },
  { brand_id: 20, brand_name: "Jaguar" },
  { brand_id: 21, brand_name: "Porsche" },
  { brand_id: 22, brand_name: "Ferrari" },
  { brand_id: 23, brand_name: "Volvo" },
  { brand_id: 24, brand_name: "Citroen" },
  { brand_id: 25, brand_name: "Lexus" },
  { brand_id: 26, brand_name: "BYD" },
  { brand_id: 27, brand_name: "Lamborghini" },
  { brand_id: 28, brand_name: "Bentley" },
  { brand_id: 29, brand_name: "ISUZU" },
  { brand_id: 30, brand_name: "Force" },
  { brand_id: 31, brand_name: "Mini" },
  { brand_id: 32, brand_name: "Mitsubishi" },
  { brand_id: 33, brand_name: "Ford" },
  { brand_id: 34, brand_name: "Daewoo" },
  { brand_id: 35, brand_name: "Opel" },
  { brand_id: 36, brand_name: "Premier" },
  { brand_id: 37, brand_name: "Hindustan Motors" },
  { brand_id: 38, brand_name: "Datsun" }
];
const brandModels = {
  1: { // MARUTI SUZUKI
    SMALL: [
      "Fronx", "Swift", "Alto 800/k 10", "Baleno", "S presso", "Ignis", "Wagon R", "Celerio", "800", "Zen/Estilo", "Omni", "A-Star", "Ritz",
      "Brezza", "SX4", "Dzire", "Eeco", "Ciaz", "Esteem/1000", "Baleno Sedan", "Versa", "Baleno Altura", "Jimny", "Vitara Brezza", "Grand vitara", "Gypsy", "Kizashi",   "Ertiga", "XL6", "Invicto"
    ]
  },
  2: { // TATA
    SMALL: [
      "Punch", "Tiago", "Altroz", "Indica/vista", "Bolt", "Nano",
      "Nexon", "Tigor", "Manza/indigo/Marina", "Sierra", "Zest", "Estate",
      "Curvv", "Harrier", "Safari/Dicor/storme", "Xenon", "Sumo/Grande/Spacio", "Hexa", "Aria"
    ],
  },
  3: { // TOYOTA
    SMALL: [
      "Taisor", "Etios Liva", "Glanza", "Etios", "Prius", "Corolla/altis/yaris", "Rumion",        "Innova/Crysta", "Hyryder", "Urban Cruizer", "Qualis", "Hycross"
,        "Fortuner", "Hilux", "Land Cruiser/Prado", "Camry", "Vellfire", "Supra"
    ],
  },
  4: { // MAHINDRA
    SMALL: [
      "Xuv 300/3XO/400", "Kuv 100", "Verito/Verito vibe", "Logan", "E 20", "TUV 300",        "Thar", "Bolero neo/Nuvo sport", "Commander/Armada", "Xuv 500", "Classic"
,        "Bolero / XL/invader/Neo plus", "Voyager", "Xylo/Quanto", "Bolero pickup", "Scorpio/Scorpio N", "Thar roxx", "Marazzo", "Alturus G4", "Xuv 700"
    ],
  },
  5: { // HYUNDAI
    SMALL: [
      "Santro", "I 10 Grand/magna/Nios", "I 20", "I 20 elite", "Eon", "Getz", "Xcent", "Accent / Viva",        "I 20 active", "Creta", "Exter", "Venue", "Aura", "Creta 2023 onwards", "Verna", "Sonata", "Creta before 2023", "Kona"
,        "Alcazar", "Santafe", "Tucson", "Ioniq 5"
    ],
  },
  6: { // KIA
    SMALL: [
      "Rio", "Sonnet",        "Seltos",        "Carens",        "Carnival - Limosine", "EV6"
    ],
  },
  7: { // SKODA
    SMALL: [
      "Fabia", "Slavia",        "Kushaq", "Rapid", "Laura",        "Octavia", "Superb", "Kodiaq"


    ],

  },
  8: { // MG
    SMALL: [
      "Commet", "Astor",        "ZS EV",        "Windsor EV", "Hector/Hector plus",        "Gloster"



    ],
  },
  9: { // VW
    SMALL: [
      "Polo", "Jetta",        "Vento", "Taigun", "T-Roc",        "Virtus", "ID.4", "Passat"


    ],
    MEDIUM: [
    ],
    LARGE: [
    ],
    XL: [],
    LUXURY: [
      "Tiguan", "Beetle"
    ]
  },
  10: { // RENAULT
    SMALL: [
      "Kwid", "Pulse", "Brio"
    ],
    MEDIUM: [
      "Scala", "Triber", "Duster", "Kiger", "Bigster"
    ],
    LARGE: [
      "Koleos", "Fluence"
    ],
    XL: [],
    LUXURY: []
  },
  11: { // HONDA
    SMALL: [
      "Brio", "Jazz", "Amaze", "WRV"
    ],
    MEDIUM: [
      "City", "Elevate", "Mobilio", "BR-V"
    ],
    LARGE: [
      "Civic23", "Accord", "CR-V"
    ],
    XL: [],
    LUXURY: []
  },
  12: { // JEEP
    SMALL: [],
    MEDIUM: [
      "Compass"
    ],
    LARGE: [
      "Cherokee", "Meridian"
    ],
    XL: [],
    LUXURY: [
      "Wrangler"
    ]
  },
  13: { // NISSAN
    SMALL: [
      "Micra", "Kicks", "Sunny", "Magnite", "Terrano"
    ],
    MEDIUM: [
      "Xtrail"
    ],
    LARGE: [],
    XL: [],
    LUXURY: []
  },
  14: { // CHEVROLET
    SMALL: [
      "Beat", "Spark", "Sail", "Sail - UVa"
    ],
    MEDIUM: [
      "Optra", "Cruze", "SRV", "Tavera", "Trial Blazer"
    ],
    LARGE: [
      "Captiva"
    ],
    XL: [],
    LUXURY: []
  },
  15: { // FIAT
    SMALL: [
      "Punto"
    ],
    MEDIUM: [
      "Linea"
    ],
    LARGE: [],
    XL: [],
    LUXURY: [
      "Abarth"
    ]
  },
  16: { // AUDI
    SMALL: [],
    MEDIUM: [],
    LARGE: [
      "A3", "A4", "A6", "A8"
    ],
    XL: [
      "Q1", "Q3", "Q5", "Q7", "Q8", "eTron - GT", "S5", "RS5"
    ],
    LUXURY: []
  },
  17: { // BMW
    SMALL: [],
    MEDIUM: [],
    LARGE: [
      "X1 / iX1", "X3", "X5", "X6", "X7"
    ],
    XL: [
      "X7 - IX", "1 Series", "2 Series", "3 Series", "5 Series", "7 Series"
    ],
    LUXURY: [
      "M2", "M4", "M5", "M8", "XM", "i4", "i7", "i5", "6GT"
    ]
  },
  18: { // MERCEDES
    SMALL: [],
    MEDIUM: [],
    LARGE: [
      "A Class", "C Class", "E Class", "B Class"
    ],
    XL: [
      "S Class", "ML", "G-Wagon", "GLS", "GLE", "GLA", "GLB"
    ],
    LUXURY: [
      "EQS", "SLS", "SLK", "AMG GT", "AMG C43", "EQS - SUV", "S Class Maybach", "GLS - Maybach", "EQE", "EQB"
    ]
  },
  19: { // LAND ROVER
    SMALL: [],
    MEDIUM: [],
    LARGE: [
      "Range Rover - Evoque", "Discovery"
    ],
    XL: [
      "Defender 90", "Defender 110", "Defender 130"
    ],
    LUXURY: [
      "Range Rover - Sports", "Freelander", "Range Rover - Vellar", "Range Rover - Auto Biography"
    ]
  },
  20: { // PORSCHE
    SMALL: [],
    MEDIUM: [],
    LARGE: [],
    XL: [],
    LUXURY: [
      "Cayenne", "Macan", "Panamera", "Taycan", "718", "911"
    ]
  },
  21: { // FERRARI
    SMALL: [],
    MEDIUM: [],
    LARGE: [],
    XL: [],
    LUXURY: [
      "Roma", "Portofino", "SF 90", "488", "458", "296", "F8", "La Ferrari"
    ]
  },
  22: { // VOLVO
    SMALL: [],
    MEDIUM: [],
    LARGE: [
      "XC40", "XC90"
    ],
    XL: [
      "S90", "C40", "V40", "S60", "XC60"
    ],
    LUXURY: []
  },
  23: { // CITROEN
    SMALL: [
      "C3"
    ],
    MEDIUM: [],
    LARGE: [
      "C5"
    ],
    XL: [],
    LUXURY: [
      "C3 Aircross"
    ]
  },
  24: { // LEXUS
    SMALL: [],
    MEDIUM: [],
    LARGE: [
      "NX 350H", "ES 300H"
    ],
    XL: [
      "RX 450H"
    ],
    LUXURY: [
      "NX 300", "LX 570", "RX 500H"
    ]
  },
  25: { // BYD
    SMALL: [],
    MEDIUM: [],
    LARGE: [
      "Atto 3"
    ],
    XL: [
      "E 6"
    ],
    LUXURY: [
      "Seal"
    ]
  },
  26: { // ISUZU
    SMALL: [],
    MEDIUM: [],
    LARGE: [
      "M U-X"
    ],
    XL: [
      "V-Cross"
    ],
    LUXURY: [
      "D-MAX"
    ]
  },
  27: { // GREAT WALL
    SMALL: [],
    MEDIUM: [],
    LARGE: [],
    XL: [],
    LUXURY: [
      "Gurkha"
    ]
  },
  28: { // MINI
    SMALL: [
      "3 Door"
    ],
    MEDIUM: [
      "Countryman"
    ],
    LARGE: [],
    XL: [
      "Clubman"
    ],
    LUXURY: []
  },
  29: { // MITSUBISHI
    SMALL: [],
    MEDIUM: [],
    LARGE: [
      "Pajero", "Montero"
    ],
    XL: [
      "Cedia", "Outlander"
    ],
    LUXURY: [
      "Lancer"
    ]
  },
  30: { // FORD
    SMALL: [
      "Ecosport", "Figo", "Fiesta", "Aspire"
    ],
    MEDIUM: [
      "Mustang", "Freestyle"
    ],
    LARGE: [
      "Endeavour", "Ikon", "Escort"
    ],
    XL: [],
    LUXURY: []
  },
  31: { // DAEWOO
    SMALL: [
      "Matiz"
    ],
    MEDIUM: [
      "Cielo"
    ],
    LARGE: [],
    XL: [],
    LUXURY: [
      "Nexia"
    ]
  },
  32: { // CHEVROLET
    SMALL: [
      "Corsa"
    ],
    MEDIUM: [
      "Astra"
    ],
    LARGE: [],
    XL: [],
    LUXURY: [
      "Vectra"
    ]
  },
  33: { // TATA
    SMALL: [
      "Rio"
    ],
    MEDIUM: [
      "Padmini"
    ],
    LARGE: [],
    XL: [],
    LUXURY: [
      "Sigma"
    ]
  },
  34: { // AMBASSADOR
    SMALL: [],
    MEDIUM: [],
    LARGE: [],
    XL: [],
    LUXURY: [
      "Ambassador"
    ]
  },
  35: { // DATSUN
    SMALL: [
      "Go", "Go+"
    ],
    MEDIUM: [],
    LARGE: [],
    XL: [],
    LUXURY: [
      "Redi Go"
    ]
  }
};
  return (
    <div className="washingpage-container" style={{ display: "flex" }}>
      <ServiceListingSidebar />

      <div className="service-container">
        <div className="top-head" style={{ display: "flex", justifyContent: "space-between", paddingLeft: 8, paddingRight: 8 }}>
          <h1 style={{ color: "linear-gradient(400deg, #ff6B00 20%, #ffBE00 100%)" }}>
            Service Providers
          </h1>
          <div className="service-header">
            <div className="date-time">
              <span className="calender">
                <SlCalender />
              </span>
              {new Date().toDateString()}
              <span className="car">
                <FaCar />
              </span>
              BMW-X5
            < button className="edit">  <a href="/ServiceListing">
                <ImPencil className="edit-pencil" /> Edit </a>
              </button>
            </div>
          </div>
        </div>

        {isPopupOpen && (
          <div className="popup-overlay">
            <div className="popup-content">
              <div className='cencel-close'> <button onClick={handleClosePopup}> X <br></br>close</button></div>
              <h2>Add Your Vehicle Details</h2>
              <div className="popup-form">
              <div className="popup-row">
              <div className="popup-column">
      
  <label>Select Brand</label>
  <select>
      <option value="" disabled selected>
        Choose Your Vehicle Brand
      </option>
      {brandName.map((brand) => (
        <option key={brand.brand_id} value={brand.brand_name}>
          {brand.brand_name}
        </option>
      ))}
    </select>
</div>

              <div className="popup-column">
              <label> Select Model</label>
                <input type="text" placeholder="Choose Your Vehicle Model"/>
              </div>
            </div>
            <div className="popup-row">
            <div className="popup-column">
  <label>Select Fuel Type</label>
  <select>
    <option value="" disabled selected>fuel type .e.g. petrol </option>
    <option value="Petrol">Petrol</option>
    <option value="Diesel">Diesel</option>
    <option value="CNG">CNG</option>
    <option value="CNG">EV</option>


  </select>
</div>
<div className="popup-column">
  <label>Select Transmission/Variant</label>
  <select>
    <option value="" disabled selected>Select a transmission type</option>
    <option value="Manual">Manual</option>
    <option value="Automatic">Automatic</option>
  </select>
</div>

            </div>
            <div className="popup-row-center">
            <div className="popup-column">
            <label> Select Date of Service </label>
                <input type="text" placeholder="select date for servicing" />
                </div>
              </div>
            </div>
              <button onClick={handleClosePopup}>Submit <FontAwesomeIcon icon={faArrowRight}></FontAwesomeIcon></button>
            </div>
          </div>
        )}


        <div className="service-list">
          {services.map((service) => (
            <div key={service.id} className={`service-card ${service.selected ? "selected" : ""}`}>
              {service.selected ? (
                <div className="service-info" style={{ color: "white", display: "flex", justifyContent: "space-between" }}>
                  <img src={service.logo} alt="" className="left-img" />
                  <div style={{ paddingLeft: "14px", width: "669px", height: "180px", display: "flex", justifyContent: "space-between", alignItems: "flex-start", paddingLeft: "18px" }}>
                    <div style={{ width: "343px", height: "180px" }}>
                      <h4 className="title-name">{service.name}</h4>
                      <ul className="des-list">
                        <li>{service.description}</li>
                        <li>{service.descriptionTwo}</li>
                      </ul>
                    
                      <div style={{ display: "flex", alignItems: "center", gap: "4px", paddingTop:"22px"}}>
                        <span style={{ fontSize: "18px", fontWeight: 600, color: "#FFBE00", textDecoration: "underline" }}>
                          Price Break-up
                        </span>
                        <img src={service.topArrow} alt="" />
                      </div>
                      {/* <ol className="des-list break-list">
                        <li>{service.priceBreakUpOne}</li>
                        <li>{service.priceBreakUpTwo}</li>
                        <li>{service.priceBreakUpThree}</li>
                        <li>{service.priceBreakUpFour}</li>
                      </ol> */}
                     <div className="price-breakup-list">
                        <div className="row-one">
                          <p>{service.priceBreakUpOne}</p>
                        </div>
                        <div className="row-two"></div>
                        <div className="row-three"></div>
                        <div className="row-four"></div>
                        <div className="row-five"></div>
                     </div>
                    </div>
                    
                    <div className="3rd" style={{ display: "flex", flexDirection: "column", width: "249px", height: "168px" }}>
                      <div className="service-meta">
                        <span><img src={service.starIcon} alt="" /> {service.rating}</span>
                        <span><img src={service.locationIcon} alt="" /> {service.location}</span>
                        <span><img src={service.distanceIcon} alt="" /> {service.distance}</span>
                      </div>
                      <div>
                        <p className="service-price">
                          <span style={{ color: "#FFBE00", paddingRight: "3px" }}>₹ </span>
                          {service.price}
                        </p>
                        <div style={{display:"flex", justifyContent:"end"}}>
                        <button
                          className="selected-btn"
                          onClick={() => handleSelect(service.id)}
                        >
                          Selected
                        </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="service-info " style={{ color: "white", display: "flex", justifyContent: "space-between" }}>
                  <img src={service.logo} alt="" className="left-img" />
                  <div style={{ paddingLeft: "14px", width: "669px", height: "180px", display: "flex", justifyContent: "space-between", alignItems: "flex-start", paddingLeft: "18px" }}>
                    <div style={{ width: "343px", height: "180px" }}>
                      <h4 className="title-name">{service.name}</h4>
                      <ul className="des-list">
                        <li>{service.description}</li>
                        <li>{service.descriptionTwo}</li>
                      </ul>
                      <div style={{ display: "flex", alignItems: "center", gap: "4px" , paddingTop:"22px" }}>
                        <span style={{ fontSize: "18px", fontWeight: 600, color: "#FFBE00", textDecoration: "underline" }}>
                          Price Break-up
                        </span>
                        <img src={service.rightArrow} alt="" />
                      </div>
                    </div>
                    <div className="3rd" style={{ display: "flex", flexDirection: "column",  width: "249px", height: "168px" }}>
                      <div className="service-meta">
                        <span><img src={service.starIcon} alt="" /> {service.rating}</span>
                        <span><img src={service.locationIcon} alt="" /> {service.location}</span>
                        <span><img src={service.distanceIcon} alt="" /> {service.distance}</span>
                      </div>
                      <div>
                        <p className="service-price">
                          <span style={{ color: "#FFBE00", paddingRight: "3px" }}>₹ </span>
                          {service.price}
                        </p>
                        <button
                          className="select-btn"
                          onClick={() => handleSelect(service.id)}
                        >
                          Select
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          ))}
          <div>
           <Link to="/customerDetails"> <div className='service-partnerarrow'>
              Proceed To Add Details <FontAwesomeIcon icon={faArrowRight}  />
             </div></Link>
          </div>
        </div>
      </div>
    </div>
  );
};

// PropTypes for validation
ServiceListing.propTypes = {
  onStepComplete: PropTypes.func,
};

export default ServiceListing;
