import React, { useState } from 'react';
import './CustomerDetails.css'; // Include the CSS file
import { faUser, faPhone, faEnvelope, faCalendar, faClock, faArrowRight,faAddressBook } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Link, useNavigate  } from 'react-router-dom';
import StatusSidebar from './StatusSidebar';
import whatsicon from "../image/whatsapp-icon.png";

const CustomerDetails = () => {
  const [startDate, setStartDate] = useState(new Date());
    const [formData, setFormData] = useState({
        name: '',
        phone: '',
        vehicleName: '',
        RegistrationNumber: '',
        dateTime: '',
        pickUpDateOption2: '',
    pickUpTime: '',
    pickUpTimeOption2: ''
    });


    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value,
        }));
    };
    const navigate = useNavigate(); 
  
  return (
    <div className="customer-details-page">
<div className="Status-sidebar">
  <StatusSidebar />
</div>
      <div className="details-form">
        <h1>Customer Details</h1>
        <form>
          <div className="customer-input-group">
            <div className="Customer-row">
              <div className="Customer-column">
                <label><FontAwesomeIcon icon={faUser} /> Enter your Name <span style={{color:' #FFBE00'}}>*</span></label>
                <input type="text" placeholder="First Name" />
              </div>
              <div className="Customer-column">
              <label> </label>
                <input type="text" placeholder="Last Name" style={{marginTop:'20px'}}/>
              </div>
            </div>
          </div>

          <div className="customer-input-group">
            <div className="Customer-row">
              <div className="Customer-column">
                <label><img src={whatsicon} /> WhatsApp No. <span style={{color:' #FFBE00'}}>*</span></label>
                <input type="text" placeholder="Enter your Whatsapp No." />
              </div>
              <div className="Customer-column">
                <label><FontAwesomeIcon icon={faPhone} /> Alternate Contact No.</label>
                <input type="text" placeholder="000-000-0000" />
              </div>
            </div>
          </div>

          <div className="customer-input-group">
            <div className="Customer-row">
              <div className="Customer-column">
                <label>Vehicle Registration No. <span style={{color:' #FFBE00'}}>*</span></label>
                <input type="text" placeholder="AB-00-CD-0000" 
                id="RegistrationNumber"
                name="RegistrationNumber"
                 required
                 value={formData.RegistrationNumber}
                 onChange={handleInputChange}
                 style={{ textTransform: 'uppercase' }}
                 maxLength={10}/>
              </div>
              <div className="Customer-column">
                <label><FontAwesomeIcon icon={faEnvelope} />  Email Address <span style={{color:' #FFBE00'}}>*</span></label>
                <input type="email" placeholder="abc@domain.com" />
              </div>
            </div>
          </div>

          <div className="customer-input-group">
            <div className="Customer-row">
              <div className="Customer-column">
                <label> <FontAwesomeIcon icon={faAddressBook} />
                Pick-Up Address <span style={{color:' #FFBE00'}}>*</span></label>
                <input type="text" placeholder="Please enter your pickup address." className="drop-address-input"/>
              </div>
              <div className="Customer-column">
                <label>Landmark <span style={{color:' #FFBE00'}}>*</span></label>
                <input type="text" placeholder="Nearest Location" className="landmark-input" />
              </div>
            </div>
          </div>

          <div className="customer-input-group">
            <div className="Customer-row">
              <div className="Customer-column">
                <label> <FontAwesomeIcon icon={faAddressBook} />
                Drop Address <span style={{color:' #FFBE00'}}>*</span></label>
                <input type="text" placeholder="Please enter your drop address." className="drop-address-input" />
              </div>
              <div className="Customer-column">
                <label>Landmark <span style={{color:' #FFBE00'}}>*</span></label>
                <input type="text" placeholder="Nearest Location" className="landmark-input" />
              </div>
            </div>
          </div>

          <div className="customer-input-group">
            <div className="Customer-row">
              <div className="Customer-column">
              <label>
        <FontAwesomeIcon icon={faCalendar} /> Select Pick-Up Date <span style={{color:' #FFBE00'}}>*</span>
      </label>
      <DatePicker
        selected={startDate}
        onChange={(date) => setStartDate(date)} // Updates date on change
        dateFormat="dd/MM/yyyy" // Shows date in dd/MM/yyyy format
        className="date-time-picker"
        required
      />
              </div>
              <div className="Customer-column">
              <label>
        <FontAwesomeIcon icon={faCalendar} /> Select Pick-Up Date (Option 2)
      </label>
      <DatePicker
                  selected={formData.pickUpDateOption2} // Use state for option 2
                  onChange={(date) => setFormData({ ...formData, pickUpDateOption2: date })} // Updates date on change
                  dateFormat="dd/MM/yyyy" // Shows date in dd/MM/yyyy format
                  className="date-time-picker"
                  placeholderText='option'
                />
              </div>
            </div>
          </div>

          <div className="customer-input-group">
            <div className="Customer-row">
              <div className="Customer-column">
              <label>
        <FontAwesomeIcon icon={faClock} /> Select Pick-Up Time <span style={{color:' #FFBE00'}}>*</span>
      </label>
      <input 
        type="time" 
        name="pickUpTime" 
        value={formData.pickUpTime} 
        onChange={handleInputChange} 
        required 
      />
              </div>
              <div className="Customer-column">
      <label>
        <FontAwesomeIcon icon={faClock} /> Select Pick-Up Time (Option 2)
      </label>
      <input 
        type="time" 
        name="pickUpTimeOption2" 
        value={formData.pickUpTimeOption2} 
        onChange={handleInputChange} 
      />
    </div>
            </div>
          </div>

         <Link to="/PaymentDetails"> 
         <button type="submit" className="Customer-submit-btn" >
            Proceed To Add Payment Details <FontAwesomeIcon icon={faArrowRight} />
          </button>
          </Link>
        </form>
      </div>
    </div>
  );
};

export default CustomerDetails;
