import React, { useState } from 'react';
import './BookingSummary.css';
import StatusSidebar from './StatusSidebar';
import bookingchack from '../image/bookingchack.png';
import logo from "../image/LOGO-03.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAddressBook, faUser, faCar, faPhone, faEnvelope, faCalendar, faClock } from '@fortawesome/free-solid-svg-icons';

const BookingSummary = () => {
  const [activeStep, setActiveStep] = useState(4);
  const [serviceId, setServiceId] = useState(1);
  const [invoiceNumber, setInvoiceNumber] = useState(1); // Initialize invoice number

  const handleNewInvoice = () => {
    setInvoiceNumber((prevNumber) => prevNumber + 1); // Increment invoice number on each new invoice
  };
  const handleNewBooking = () => {
    setServiceId((prevId) => prevId + 1);
  };

  return (
    <div className="customer-detail-container">
      <div className="Status-sidebar">
        <StatusSidebar activeStep={activeStep} />
      </div>

      <div className="rightsidebar">
        <h1>Booking Summary</h1>
        <div className="customer-form-container">
          <div className="successfullDiv">
            <div className="top">
              <div>
                <img src={bookingchack} alt="" />
              </div>

              <div>
                <p className="title">
                  Your Car Service booking <br /> has been Confirmed.</p>
              </div>
              <div className="serviceIdDiv">
                <p className="servicesId">Service Id: 24AK09000{serviceId}</p>
              </div>
            </div>

            <div className="custo-Detail-Address">
              <div className="cust-Detail">
                <div className="cust-Detail-heading-left">
                  <h4>Customer Details</h4>
                </div>
                <div className="cust-Detail-inside">
                  <p> <FontAwesomeIcon icon={faUser} className="BOOKicon" /> Mayank Karode</p>
                  <p> <FontAwesomeIcon icon={faCar} className="BOOKicon" /> BMW-X5</p>
                  <p> <FontAwesomeIcon icon={faPhone} className="BOOKicon" /> 9999-999-999</p>
                  <p> <FontAwesomeIcon icon={faEnvelope} className="BOOKicon" /> xyz@gmail.com</p>
                  <p> <FontAwesomeIcon icon={faCalendar} className="BOOKicon" /> dd/mm/yyyy</p>
                  <p> <FontAwesomeIcon icon={faClock} className="BOOKicon" /> 9 AM - 12 Noon</p>
                </div>

              </div>
              <div className="cust-Address">
                <div className="cust-Detail-heading-right">
                  <h4 className="cust-Detail-p1">Addresses</h4>
                </div>
                <div className="cust-Detail-child-2">
                  <p className="cust-Detail-p2">
                    <FontAwesomeIcon icon={faAddressBook} /> Pick-Up
                  </p>
                  <p className="cust-Detail-p3">
                    162 Maharana Pratap Colony, Bhopal, (MP)
                  </p>
                </div>

                <div className="cust-Detail-child-3">
                  <p className="cust-Detail-p4">
                    <FontAwesomeIcon icon={faAddressBook} /> Drop</p>
                  <p className="cust-Detail-p5">
                    162 Maharana Pratap Colony, Bhopal, (MP)
                  </p>
                </div>
                <p className="cust-Detail-p6">Terms & Conditions</p>
              </div>
            </div>
          </div>

          <div className="successfullDetailsDiv">
            <div className='booklogo' >
              <img
                src={logo}
                alt="navlogo"
              />
              <p> AutoKaar </p></div>

            <p>Service Provider Name: <span>The Detailing Mafia </span></p>
            <p id='invoiceyeelo'>Invoice No.: AK24090{invoiceNumber}</p>
            <div className="booking-summary-right-sec">
              <p id='selectedbook'>Services Selected</p>
              <ol className="booking-summary-right-ul-lis">
                <li id='bookli'>
                  <p >1.UV Protection</p>
                  <span>₹ </span><p>1500</p>
                </li>
                <li id='bookli'>
                  <p>2.Interior Enrichment</p>
                  <span>₹ </span> <p> 2500</p>
                </li>
                <li id='bookli'>
                  <p>3.Surface Correction</p>
                  <span>₹ </span> <p> 3000</p>
                </li>
                <li id='bookli'>
                  <p>4.UV Protection</p>
                  <span>₹ </span> <p> 2000</p>
                </li>
              </ol>
              <div className='subtotalbook'>
                <p>SubTotal</p>
                <p id='bookli'> <span>₹ </span> 9000</p>
              </div>
              <div className='subtotalbook'>
                <p>Taxes & Convenience fees</p>
                <p id='bookli'><span>₹ </span>200</p>
              </div>
              <div className='subtotalbook'>
                <p>Grand Total</p>
                <p id='bookli'> <span>₹ </span>9200</p>
              </div>
              <div className='subtotalbook'>
                <p>Amount Paid</p>
                <p id='bookli'> <span>₹ </span>8280</p>
              </div>
              <div className='subtotalbook'>
                <p>Amount Pending</p>
                <p id='bookli'> <span>₹ </span>920</p>
              </div>
              <div className='subtotalbook'>
                <p>Transaction date:</p>
                <p>12/10/2024</p>
              </div>
              <div className='subtotalbook'>
                <p>Transaction id:</p>
                <p>428419556782</p>
              </div>
              <p id='bookdownload'>Download Invoice</p>
            </div>
            <div className="custo-fotterdiv">
              <p className="custo-support-p1"> Cancellation Policy</p>
              <p className="custo-support-p2">Contact Customer Support</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookingSummary;


