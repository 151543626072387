import React from "react";
import "./ExteriortreatmentTable.css";

const HandlampRestorationTable = () => {
  // Treatment details array
  const details = [
    {
      title: "0-11 Months",
      result: "No",
    },
    {
      title: "1-1yr 11 Months",
      result: "No",
    },
    {
      title: "2-2yr 11 Months",
      result: "No",
    },
    {
      title: "3-3yr 11 Months",
      result: "No",
    },
    {
      title: "4-4yr 11 Months",
      result: "Yes",
    },
    {
      title: "5-5yr 11 Months",
      result: "Yes",
    },
    {
      title: "6yr+",
      result: "Yes",
    },
  ];

  return (
    <div className="treatment-table-container">
      <div className="treatment-details">
        <div className="treatment-detail-first">
          <h5>Headlamp Restoration</h5>
          <p>Headlamp Restoration</p>
        </div>

        <div className="treatment-detail">
          <h5>Recommended</h5>
          <p>
            Recommended on vehicles where headlight's outer shell became yellow
            due to harmful UV rays, hard water, and other natural factors.
          </p>
        </div>

        <div className="treatment-detail">
          <h5>Process</h5>
          <p>
            At first, cut compounds are used to cut down the top layer of
            dust/impurity. Then fine coarse finishing/rubbing compounds are used
            to treat swirl marks effectively. Your headlamps will again restore
            the life and save up to 80% cost from buying a new one, lasting for
            2-3 years unless broken.
          </p>
        </div>

        {/* Display treatment details */}
        {details.map((detail, index) => (
          <div key={index} className="treatment-detail">
            <h5>{detail.title}</h5>
            <p>{detail.result}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default HandlampRestorationTable;