import React, { useState } from "react";
import "./ExteriortreatmentTable.css";

const CeramicCoatingTable = () => {
  // State to manage which treatment detail is displayed
  const [displayedDetail, setDisplayedDetail] = useState("0-11 Months");

  // Function to update displayedDetail based on car age
  const updateDetail = (monthsOld) => {
    if (monthsOld >= 72) {
      // 72 months = 6 years
      setDisplayedDetail("6yr+"); // Show "6yr+"
    } else if (monthsOld <= 11) {
      setDisplayedDetail("0-11 Months"); // Show "0-11 Months"
    } else {
      const index = Math.floor(monthsOld / 12); // Calculate index
      if (index <= 5) {
        setDisplayedDetail(`${index}-${index + 1}yr 11 Months`); // Show the corresponding detail based on age
      } else {
        setDisplayedDetail("6yr+"); // Fallback to "6yr+" for any other case
      }
    }
  };

  return (
    <div className="treatment-table-container">
      <div className="treatment-details">
        <div className="treatment-detail-first">
          <h5>Ceramic Coating</h5>
          <p>Ceramic Coating</p>
        </div>

        <div className="treatment-detail">
          <h5>Recommended</h5>
          <p>Recommended on cars with fresh paint unless scratched</p>
        </div>

        <div className="treatment-detail">
          <h5>Process</h5>
          <p>
            Starts with washing and then claying, swirl marks removal, and
            preparation of painted surface. Then post surface finish ceramic
            coating is applied with an applicator pad, let it dry for 5-8 hours
            depending on the weather conditions, and the vehicle is ready to
            deliver after 24 hours of process.
          </p>
        </div>

        {/* Displaying only the selected treatment detail */}
        {displayedDetail === "0-11 Months" && (
          <div className="treatment-detail">
            <h5>0-11 Months</h5>
            <p>Yes</p>
          </div>
        )}
        {displayedDetail === "1-1yr 11 Months" && (
          <div className="treatment-detail">
            <h5>1-1yr 11 Months</h5>
            <p>Yes</p>
          </div>
        )}
        {displayedDetail === "2-2yr 11 Months" && (
          <div className="treatment-detail">
            <h5>2-2yr 11 Months</h5>
            <p>Yes</p>
          </div>
        )}
        {displayedDetail === "3-3yr 11 Months" && (
          <div className="treatment-detail">
            <h5>3-3yr 11 Months</h5>
            <p>No</p>
          </div>
        )}
        {displayedDetail === "4-4yr 11 Months" && (
          <div className="treatment-detail">
            <h5>4-4yr 11 Months</h5>
            <p>No</p>
          </div>
        )}
        {displayedDetail === "5-5yr 11 Months" && (
          <div className="treatment-detail">
            <h5>5-5yr 11 Months</h5>
            <p>No</p>
          </div>
        )}
        {displayedDetail === "6yr+" && (
          <div className="treatment-detail">
            <h5>6yr+</h5>
            <p>No</p>
          </div>
        )}
      </div>

      {/* Example of triggering the update (you can replace this with your own logic) */}
      <button onClick={() => updateDetail(6)}>Check 0-11 Months</button>
      <button onClick={() => updateDetail(13)}>Check 1-1yr 11 Months</button>
      <button onClick={() => updateDetail(25)}>Check 2-2yr 11 Months</button>
      <button onClick={() => updateDetail(37)}>Check 3-3yr 11 Months</button>
      <button onClick={() => updateDetail(49)}>Check 4-4yr 11 Months</button>
      <button onClick={() => updateDetail(61)}>Check 5-5yr 11 Months</button>
      <button onClick={() => updateDetail(75)}>Check 6yr+</button>
    </div>
  );
};

export default CeramicCoatingTable;