import React, { useState } from "react";
import "./ExteriortreatmentTable.css";

const EngineprotectionTable = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [displayedDetail, setDisplayedDetail] = useState(null); // State for the displayed treatment detail

  // Treatment details array
  const details = [
    {
      title: "0-11 Months",
      result: "Yes",
    },
    {
      title: "1-1yr 11 Months",
      result: "Yes",
    },
    {
      title: "2-2yr 11 Months",
      result: "Yes",
    },
    {
      title: "3-3yr 11 Months",
      result: "Yes",
    },
    {
      title: "4-4yr 11 Months",
      result: "Yes",
    },
    {
      title: "5-5yr 11 Months",
      result: "Yes",
    },
    {
      title: "6yr+",
      result: "Yes",
    },
  ];

  // Filter details based on search input
  const filteredDetails = details.filter((detail) =>
    detail.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // If no search term is entered, default to first treatment detail
  const displayDetails = searchTerm === "" ? [details[0]] : filteredDetails;

  // Function to show first detail when button is clicked
  const showFirstDetail = () => {
    setDisplayedDetail(details[0]); // Set first treatment detail
  };

  return (
    <div className="treatment-table-container">
      <div className="treatment-details">
        <div className="treatment-detail-first">
          <h5>Engine Protection</h5>
          <p>Engine Dressing</p>
          <p>Engine Lacquer Coating</p>
          <p>Rodent Repellent Spray</p>
        </div>

        <div className="treatment-detail">
          <h5>Recommended</h5>
          <p>To keep the engine looking new.</p>
          <p>
            Protects plastic covers from heat, thus increasing life and look.
          </p>
          <p>Protects engine wires from rat bites.</p>
        </div>

        <div className="treatment-detail">
          <h5>Process</h5>
          <p>
            Clean the engine compartment, remove oil spreads and apply polish.
          </p>
          <p>
            Clean the engine compartment, remove oil spreads, and apply engine
            coating or engine lacquer.
          </p>
          <p>
            Clean the engine compartment, remove oil spreads, and cover all the
            wires, rubber, and foam parts using rodent spray. Also, apply over
            the inside of the wheel arch area to prevent rats from entering
            inside the engine area or vehicle.
          </p>
        </div>

        {/* Search Input */}
        <input
          type="text"
          placeholder="Search treatment duration..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />

        {/* Button to show the first treatment detail */}
        {/* <button onClick={showFirstDetail}>
          Show First Treatment (0-11 Months)
        </button> */}

        {/* Display treatment details */}
        {displayedDetail ? (
          <div className="treatment-detail">
            <h5>{displayedDetail.title}</h5>
            <p>{displayedDetail.result}</p>
          </div>
        ) : searchTerm === "" ? (
          displayDetails.map((detail, index) => (
            <div key={index} className="treatment-detail">
              <h5>{detail.title}</h5>
              <p>{detail.result}</p>
            </div>
          ))
        ) : filteredDetails.length > 0 ? (
          filteredDetails.map((detail, index) => (
            <div key={index} className="treatment-detail">
              <h5>{detail.title}</h5>
              <p>{detail.result}</p>
            </div>
          ))
        ) : (
          <p>No results found</p>
        )}
      </div>
    </div>
  );
};

export default EngineprotectionTable;