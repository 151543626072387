import React, { useState,useContext } from 'react';
import './Washing.css'; 
import image from '../image/washcar.jpg';
import image1 from '../image/serviceimage/anti rust.jpg';
import Silencer from '../image/inside service/silencer.jpg';
import ServiceSidebar from './ServiceSidebar';
import {faXmark, faArrowRight} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { ServiceContext } from './ServiceContext'; // Import the context

const ANTIRUSTPROTECTION = () => {
    const { addService, removeService } = useContext(ServiceContext); // Use the context
    const [isAdded1, setIsAdded1] = useState(false);
    const [isAdded2, setIsAdded2] = useState(false);

    const handleAddToCart1 = () => {
        if (!isAdded1) {
            setIsAdded1(true);
            addService(); // Increment global service count
        }
    };
  
    const handleCancel1 = () => {
        if (isAdded1) {
            setIsAdded1(false);
            removeService(); // Decrement global service count
        }
    };

    const handleAddToCart2 = () => {
        if (!isAdded2) {
            setIsAdded2(true);
            addService(); // Increment global service count
        }
    };
  
    const handleCancel2 = () => {
        if (isAdded2) {
            setIsAdded2(false);
            removeService(); // Decrement global service count
        }
    };


    return (
        <div className='washingpage-container' style={{display:'flex'}}>
        <ServiceSidebar />

               <div className="main-contant">
                <div className="services-contant">
            <div className="services-contact-image">
                <img src={image1} alt="Car" />
            </div>
            <div className="services-contact-form">
                <h2 style={{ fontFamily: '"Chakra Petch", sans-serif' }}>Anti Rust Protection</h2>
                <h3 style={{ fontFamily: '"Chakra Petch", sans-serif' }}>Underbody/Anti-Rust Coating:</h3>
                <p>A protective layer applied to the vehicle's underside to prevent rust and corrosion.</p>
                <h3 style={{ fontFamily: '"Chakra Petch", sans-serif' }}>Benefits:</h3>
                <li>	Protects against salt, moisture, and road debris.</li>
                <li>	Reduces noise and vibrations.</li>
                <li>	Extends vehicle lifespan and lowers maintenance costs.</li>               </div>
        </div>
        <div className="midd-contant">
            <div className="midd-contact-form">
                <h2 style={{ fontFamily: '"Chakra Petch", sans-serif' }}>Silencer Coating</h2>
                <p>A protective layer for the exhaust system (muffler, tailpipe, catalytic converter), preventing rust, heat damage, and leaks. It reduces maintenance costs and increases the lifespan.</p>
               </div>
               <div className="midd-contact-image">
                <img src={Silencer} alt="Car" />
                <div className='add-cart'>
                {isAdded1 &&(
                <button className='cencel' onClick={handleCancel1}>
                    <FontAwesomeIcon icon={faXmark} />
                    </button> 
                )}
                              <button className={`added ${isAdded1 ? 'added-active' : ''}`} 
        onClick={handleAddToCart1}
    >
                {isAdded1 ? "Service Added" : "Add To Cart"}
              </button>            
            </div>
            </div>
        </div>
        <div className="midd-contant">
            <div className="services-contact-form">
                <h2 style={{ fontFamily: '"Chakra Petch", sans-serif' }}>Internal Panel protection</h2>
                <p>Content to be provided</p>
                {/* <h3 style={{ fontFamily: '"Chakra Petch", sans-serif' }}>Underbody/Anti-Rust Coating:</h3>
                <p>A protective layer applied to the vehicle's underside to prevent rust and corrosion.</p>
                <h3 style={{ fontFamily: '"Chakra Petch", sans-serif' }}>Benefits:</h3>
                <li>	Protects against salt, moisture, and road debris.</li>
                <li>	Reduces noise and vibrations.</li>
                <li>	Extends vehicle lifespan and lowers maintenance costs.</li>           */}
                     </div>
       
        <div className="midd-contact-image">
                <img src={image1} alt="Car" />
                <div className='add-cart'>
                {isAdded2 &&(
                <button className='cencel' onClick={handleCancel2}>
                    <FontAwesomeIcon icon={faXmark} />
                    </button> 
                )}
                              <button className={`added ${isAdded2 ? 'added-active' : ''}`} 
        onClick={handleAddToCart2}
    >
                {isAdded2 ? "Service Added" : "Add To Cart"}
              </button>            
            </div>
            </div>
            </div>
           <Link to="/ServiceListing"><div className='service-partnerarrow'> Proceed to Select Service Partner <FontAwesomeIcon icon={faArrowRight} /></div></Link> 
        </div>
      </div>
    );
};

export default ANTIRUSTPROTECTION;
