import React, { useState } from "react";
import "./ExteriortreatmentTable.css";

const ExteriortreatmentTable = () => {
  // Treatment details ko ek array ke form me store kiya
  const treatmentDetails = [
    {
      title: "0-11 Months",
      details: ["Yes", "No", "Yes", "No"],
    },
    {
      title: "2-2yr 11 Months",
      details: ["Yes", "Yes", "Yes", "No"],
    },
    {
      title: "3-3yr 11 Months",
      details: ["Yes", "No", "No", "No"],
    },
    {
      title: "4-4yr 11 Months",
      details: ["Yes", "No", "Yes", "Yes"],
    },
    {
      title: "5-5yr 11 Months",
      details: ["No", "Yes", "Yes", "Yes"],
    },
    {
      title: "6yr+",
      details: ["No", "No", "Yes", "yes"],
    },
  ];

  // State to manage which treatment detail is displayed
  const [displayedDetail, setDisplayedDetail] = useState(treatmentDetails[0]); // Default to "0-11 Months"

  // Function to update displayedDetail based on some input logic
  const updateDetail = (monthsOld) => {
    if (monthsOld <= 11) {
      setDisplayedDetail(treatmentDetails[0]); // Show "0-11 Months"
    } else if (monthsOld >= 72) {
      // 72 months = 6 years
      setDisplayedDetail(treatmentDetails[treatmentDetails.length - 1]); // Show "6yr+"
    } else {
      // Logic to find the correct treatment detail based on age
      const index = Math.floor(monthsOld / 12) - 1; // Calculate index
      setDisplayedDetail(
        treatmentDetails[Math.min(index, treatmentDetails.length - 2)]
      ); // Set detail
    }
  };

  return (
    <div className="treatment-table-container">
      <div className="treatment-details">
        <div className="treatment-detail-first">
          <h5>Exterior Rejuvenation</h5>
          <p>UV-protection</p>
          <p>Paint Correction</p>
          <p>Surface Refinement</p>
          <p>Surface Correction</p>
        </div>

        <div className="treatment-detail">
          <h5>Recommended</h5>
          <p>Wax application for UV-protection</p>
          <p>Ideal for 0-12 months old cars only.</p>
          <p>
            Ideal for 12-36 months old cars only. Also, when there is no paint
            peeling or deep scratch visible.
          </p>
          <p>
            Ideal when multiple deep scratches are visible on the painted
            surface.
          </p>
        </div>

        <div className="treatment-detail">
          <h5>Process</h5>
          <p>
            Wax protects the painted surface from UV rays, water deposit (based
            on the grade used), usually varies from 7 - 45 days. No machine work
            required.
          </p>
          <p>
            Fine course finishing/rubbing compounds are used to treat swirl
            marks well. Then apply wax to protect the fresh paint from harmful
            UV rays and water deposition for a certain number of days.
          </p>
          <p>
            First, cut compounds are used to cut down hairline scratches, then
            fine course finishing/rubbing compounds are used to treat swirl
            marks well. Then apply wax to protect the fresh paint from harmful
            UV rays and water deposition for a certain number of days.
          </p>
          <p>
            First, cut compounds are used to cut down deep scratches, then fine
            course finishing/rubbing compounds are used to treat swirl marks
            well. Then apply wax to protect the fresh paint from harmful UV rays
            and water deposition for a certain number of days.
          </p>
        </div>

        {/* Displaying the currently selected treatment detail */}
        <div className="treatment-detail">
          <h5>{displayedDetail.title}</h5>
          {displayedDetail.details.map((item, i) => (
            <p key={i}>{item}</p>
          ))}
        </div>
      </div>

      {/* Example of triggering the update (you can replace this with your own logic) */}
      {/* <button onClick={() => updateDetail(6)}>Check 0-11 Months</button>
      <button onClick={() => updateDetail(13)}>Check 2-2yr 11 Months</button>
      <button onClick={() => updateDetail(37)}>Check 3-3yr 11 Months</button>
      <button onClick={() => updateDetail(75)}>Check 6yr+</button> */}
    </div>
  );
};

export default ExteriortreatmentTable;