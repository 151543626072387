import React from "react";
import "./ExteriortreatmentTable.css";

const WindshieldRestorationTable = () => {
  // Treatment details ko ek array ke form me store kiya
  const treatmentDetails = [
    {
      title: "0-11 Months",
      detail: "No, No",
    },
    {
      title: "1-1yr 11 Months",
      detail: "No, Yes",
    },
    {
      title: "2-2yr 11 Months",
      detail: "Yes, Yes",
    },
    {
      title: "3-3yr 11 Months",
      detail: "Yes, Yes",
    },
    {
      title: "4-4yr 11 Months",
      detail: "Yes, Yes",
    },
    {
      title: "5-5yr 11 Months",
      detail: "Yes, Yes",
    },
    {
      title: "6yr+",
      detail: "Yes, Yes",
    },
  ];

  // State to manage which treatment detail is displayed
  const [displayedDetail, setDisplayedDetail] = React.useState(
    treatmentDetails[0]
  ); // Default to 0-11 Months

  // Function to update displayedDetail based on car age
  const updateDetail = (monthsOld) => {
    if (monthsOld >= 72) {
      // 72 months = 6 years
      setDisplayedDetail(treatmentDetails[6]); // Show "6yr+"
    } else if (monthsOld <= 11) {
      setDisplayedDetail(treatmentDetails[0]); // Show "0-11 Months"
    } else {
      const index = Math.floor(monthsOld / 12); // Calculate index
      if (index <= 5) {
        setDisplayedDetail(treatmentDetails[index]); // Show the corresponding detail based on age
      } else {
        setDisplayedDetail(treatmentDetails[6]); // Fallback to "6yr+" for any other case
      }
    }
  };

  return (
    <div className="treatment-table-container">
      <div className="treatment-details">
        <div className="treatment-detail-first">
          <h5>Windshield Restoration</h5>
          <p>Hair Line Scratch and Swirl Marks Removal</p>
          <p>Hard Water Removal</p>
        </div>

        <div className="treatment-detail">
          <h5>Recommended</h5>
          <p>
            A special compound is used to buff the windshield area, which will
            remove swirl marks enhancing night visibility and light distortion.
          </p>
          <p>Removal of hard water marks from glasses.</p>
        </div>

        <div className="treatment-detail">
          <h5>Process</h5>
          <p>
            Special compounds are used with a DA machine which contains cerium
            powder to help in the removal of scratches. We also ensure that the
            glass should not get overheated to avoid cracks. Usually takes 3-4
            hours and should not use regular compounds (as used in paint
            correction).
          </p>
          <p>
            Special liquid is used to remove scaling and hard water mark
            depositions on glasses such as windshields and windows.
          </p>
        </div>

        {/* Displaying only the selected treatment detail */}
        <div className="treatment-detail">
          <h5>{displayedDetail.title}</h5>
          <p>{displayedDetail.detail}</p>
        </div>

        {/* Example of triggering the update (you can replace this with your own logic) */}
        {/* <div className="age-buttons">
          <button onClick={() => updateDetail(6)}>Check 0-11 Months</button>
          <button onClick={() => updateDetail(13)}>
            Check 1-1yr 11 Months
          </button>
          <button onClick={() => updateDetail(25)}>
            Check 2-2yr 11 Months
          </button>
          <button onClick={() => updateDetail(37)}>
            Check 3-3yr 11 Months
          </button>
          <button onClick={() => updateDetail(49)}>
            Check 4-4yr 11 Months
          </button>
          <button onClick={() => updateDetail(61)}>
            Check 5-5yr 11 Months
          </button>
          <button onClick={() => updateDetail(75)}>Check 6yr+</button>
        </div> */}
      </div>
    </div>
  );
};

export default WindshieldRestorationTable;