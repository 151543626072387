import React from "react";
import "./ExteriortreatmentTable.css";

const AccareTable = () => {
  return (
    <div className="treatment-table-container">
      <div className="treatment-details">
        <div className="treatment-detail-first">
          <h5>AC Care</h5>
          <p>AC Duct Cleaning</p>
          <p>AC Evaporator Coil Cleaning</p>
        </div>

        <div className="treatment-detail">
          <h5>Recommended</h5>
          <p>
            Done to remove Bad Odour from Car AC and Also to kill orgasms and
            bacterias. Ideally it should be repeated in every 6 months
          </p>
          <p>Suggested once after every 20,000 Kms Run</p>
        </div>

        <div className="treatment-detail">
          <h5>Process</h5>
          <p>
            A thin Pipe is dragged inside the AC Duct and a Foam is released
            putting pressure on the Nozzle. This Foam Contains Antimicrobial
            Property and thus Cleans the Duct area and leave a fresh Fragnance
            after that.{" "}
          </p>
          <p>
            AC Evaporator Coil contains pores and small holes to Pass-on Air
            which gets choked due to dust collection and then comming in contact
            of Water During Rainy Season and Car Wash, hence the mix becomes
            hard and blocks the holes to pass air freely. Hence, its cleaning is
            important to maintain the air pressure flow regular wiz-a-wiz
            efficiency{" "}
          </p>
        </div>

        <div className="treatment-detail">
          <h5>0-11 Months</h5>
          <p>Yes</p>
          <p>No</p>
        </div>
        <div className="treatment-detail">
          <h5>1-1yr 11 Months</h5>
          <p>Yes</p>
          <p>No</p>
        </div>
        <div className="treatment-detail">
          <h5>2-2yr 11 Months</h5>
          <p>Yes</p>
          <p>Yes</p>
        </div>
        <div className="treatment-detail">
          <h5>3-3yr 11 Months</h5>
          <p>Yes</p>
          <p>Yes</p>
        </div>
        <div className="treatment-detail">
          <h5>4-4yr 11 Months</h5>
          <p>Yes</p>
          <p>Yes</p>
        </div>
        <div className="treatment-detail">
          <h5>5-5yr 11 Months</h5>
          <p>Yes</p>
          <p>Yes</p>
        </div>
        <div className="treatment-detail">
          <h5>6yr+</h5>
          <p>Yes</p>
          <p>Yes</p>
        </div>
      </div>
    </div>
  );
};
export default AccareTable;