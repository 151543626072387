import React from "react";
import "./ExteriortreatmentTable.css";

const InteriorteatmentTable = () => {
  // Treatment details ko ek array ke form me store kiya
  const treatmentDetails = [
    {
      title: "0-11 Months",
      details: ["if required", "if required", "if required", "if required"],
    },
    { title: "2-2yr 11 Months", details: ["Yes", "Yes", "Yes", "Yes"] },
    { title: "3-3yr 11 Months", details: ["Yes", "Yes", "Yes", "Yes"] },
    { title: "4-4yr 11 Months", details: ["Yes", "Yes", "Yes", "Yes"] },
    { title: "5-5yr 11 Months", details: ["Yes", "Yes", "Yes", "Yes"] },
    { title: "6yr+", details: ["Yes", "Yes", "Yes", "Yes"] },
  ];

  // By default, show first treatment and last treatment based on your requirements
  const filteredDetails = [
    treatmentDetails[0],
    treatmentDetails[treatmentDetails.length - 1],
  ];

  return (
    <div className="treatment-table-container">
      <div className="treatment-details">
        <div className="treatment-detail-first">
          <h5>Interior Cleaning</h5>
          <p>Liquid Interior Cleaning</p>
          <p>Form based Cleaning</p>
          <p>Antimicrobial Interior</p>
          <p>Deep Cleaning</p>
        </div>

        <div className="treatment-detail">
          <h5>Recommended</h5>
          <p>
            Cost effective Treatment. Recommended on Fabric based Seat Covers
          </p>
          <p>
            Premium Treatment. Recommended on Leather Seat Covers for better
            life
          </p>
          <p>Preferred when there is Fungus inside the Car</p>
          <p>It is Done Post Removal of Seats</p>
        </div>

        <div className="treatment-detail">
          <h5>Process</h5>
          <p>
            A liquid chemical designed for application on seats, roof,
            dashboard, door trims, and all plastic, fabric, or leather surfaces.
          </p>
          <p>
            Foam based Chemical (Aerosol) to apply over Seats, Roof, Dashboard,
            Door Trims, All Plastic and Fabric/Leather Areas
          </p>
          <p>
            Foam based Chemical (Aerosol) to apply over Seats, Roof, Dashboard,
            Door Trims, All Plastic and Fabric/Leather Areas
          </p>
          <p>
            Post removal of Seats, Clean the Carpet area and then vacuuming,
            Cleaning is done properly
          </p>
        </div>

        {/* Filtered treatment details dikhai jayengi */}
        {filteredDetails.map((detail, index) => (
          <div key={index} className="treatment-detail">
            <h5>{detail.title}</h5>
            {detail.details.map((item, i) => (
              <p key={i}>{item}</p>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default InteriorteatmentTable;