import react from "react";

const AntirustTable = () => {
  return (
    <div className="treatment-table-container">
      <div className="treatment-details">
        <div className="treatment-detail-first">
          <h5>Antirust Couting</h5>
          <p>Under Chassis Antirust Coating</p>
          <p>Silencer Coating</p>
          <p>Internal Panel Protection</p>
        </div>

        <div className="treatment-detail">
          <h5>Recommended</h5>
          <p>
            Recommended in New Cars to Proetct the Chassis Part from Rust in the
            Long Run. Once Rusted, it can't be reversed
          </p>
          <p>
            Recommended to reduce noise of silencer and make smooth to work
            silently. Also protects from heat-cold, protection from overall
            damage and wear and tear.
          </p>
          <p>
            Suggested to protect interior panel of car from wear and tear,
            environmental factors and corrosion. Mostly Prominent in Coastal
            Areas
          </p>
        </div>

        <div className="treatment-detail">
          <h5>Process</h5>
          <p>
            Underbody Part washed Properly nd thenafter Drying it Polymer
            coating on under chassis part applied and lsft to dry out for 45
            minutes minimum for best results. Underbody Coating usually
            available in 2mm thickness and 5mm thickness.
          </p>
          <p>
            Underbody is Washed Properly, All the dust particles are removed and
            applied through Aerosol. Silencer Coatings are capable to bear heat
            ranging from 150 Degree C to 400 Degree Celcius depending upon
            brand.
          </p>
          <p>
            Liquid chemical is sprayed inside the Door Trims. Applied on all 4
            Doors. Comes with 5 Years Warranty
          </p>
        </div>

        <div className="treatment-detail">
          <h5>0-11 Months</h5>
          <p>Yes</p>
          <p>Yes</p>
          <p>Yes</p>
        </div>
        <div className="treatment-detail">
          <h5>1 1yr 11 Months</h5>
          <p>Yes</p>
          <p>Yes</p>
          <p>Yes</p>
        </div>
        <div className="treatment-detail">
          <h5>2-2yr 11 Months</h5>
          <p>No</p>
          <p>No</p>
          <p>Yes</p>
        </div>
        <div className="treatment-detail">
          <h5>3-3yr 11 Months</h5>
          <p>No</p>
          <p>No</p>
          <p>No</p>
        </div>
        <div className="treatment-detail">
          <h5>4 4yr 11 Months</h5>
          <p>No</p>
          <p>No</p>
          <p>No</p>
        </div>
        <div className="treatment-detail">
          <h5>5-5yr 11 Months</h5>
          <p>No</p>
          <p>No</p>
          <p>No</p>
        </div>
        <div className="treatment-detail">
          <h5>6yr+</h5>
          <p>No</p>
          <p>No</p>
          <p>No</p>
        </div>
      </div>
    </div>
  );
};
export default AntirustTable;