import React, { useState ,useContext} from 'react';
import './Washing.css'; 
import ceramic1 from '../image/inside service/ceramic-coating 1.webp'
import ceramic2 from '../image/inside service/ceramic coating00.webp'
import ceramic3 from '../image/inside service/ceramic 2.jpg'
import image from '../image/washcar.jpg';
import ServiceSidebar from './ServiceSidebar';
import {faXmark, faArrowRight} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ServiceContext } from './ServiceContext'; // Import the context
import { Link } from 'react-router-dom';


const Creamiccoating= () => {
    const { addService, removeService } = useContext(ServiceContext); // Use the context
    const [isAdded1, setIsAdded1] = useState(false);

    const handleAddToCart1 = () => {
        if (!isAdded1) {
            setIsAdded1(true);
            addService(); // Increment global service count
        }
    };
  
    const handleCancel1 = () => {
        if (isAdded1) {
            setIsAdded1(false);
            removeService(); // Decrement global service count
        }
    };

    return (
        <div className='washingpage-container' style={{display:'flex'}}>
        <ServiceSidebar />

               <div className="main-contant">
    <div className="midd-contant">      
    <div className="services-contact-form">
    <p>A liquid protective layer (6-8 microns thick) applied to vehicles that solidifies upon air exposure, creating an invisible barrier against damage.
    </p>
     <h2 style={{ fontFamily: '"Chakra Petch", sans-serif' }}>Ceramic & Graphene Coatings</h2>
           <h3 style={{ fontFamily: '"Chakra Petch", sans-serif' }}>Available in two variants:</h3>

         <li>	Maintenance (requires booster coat)</li>
         <li>	Without maintenance
         </li>
         <h3 style={{ fontFamily: '"Chakra Petch", sans-serif' }}>Benefits:</h3>
<li>	Adds a glossy, mirror-like finish.
</li>
<li>	Protects against hairline scratches.
</li>

<li>	Prevents hard water stains.
</li>

<li>	Shields from harmful UV rays.
</li>

<li>	Hydrophobic, making cleaning easier.
</li>

<li>	Creates a non-sticky surface.
</li>
<h3 style={{ fontFamily: '"Chakra Petch", sans-serif' }}>Types:</h3>
<li>	9H Ceramic Coating (20-30 microns)
</li>
<li>	10H Ceramic Coating (30-40 microns)
</li>

<li>	Graphene Coating (1-5 microns) – Although thinner, it offers superior hardness compared to 9H and 10H.
</li>
<h3 style={{ fontFamily: '"Chakra Petch", sans-serif' }}>Note:
</h3>
<li>9H and 10H refer to the hardness level, measured on the Mohs scale.</li>
<li>Specific coatings are available for body, glass, and alloy wheels.
</li>
</div>
<div className="services-contact-image">
                <img src={ceramic1} alt="Car" />
                <div className='add-creamic-cart' style={{display:'flex' , justifyContent:'space-around'}}>
                        <a href='/ServiceListing' style={{color:'#FFBE00', fontFamily: '"Chakra Petch", sans-serif', marginRight:'50px' , marginTop:'25px'}}> Talk to our Experts</a>
                        <div className='add-cart'>
                {isAdded1 &&(
                <button className='cencel' onClick={handleCancel1}>
                    <FontAwesomeIcon icon={faXmark} />
                    </button> 
                )}
                              <button className={`added ${isAdded1 ? 'added-active' : ''}`} 
        onClick={handleAddToCart1}
    >
                {isAdded1 ? "Service Added" : "Add To Cart"}
              </button>            
            </div>                       </div>

            </div></div>
            <Link to="/ServiceListing" ><div className='service-partnerarrow'> 
            Proceed to Select Service Partner <FontAwesomeIcon icon={faArrowRight} />
          </div> </Link> 
        </div>
        </div>
      
    );
};

export default Creamiccoating;
